import { formatDate } from '@angular/common';
import { capitalize } from 'lodash';
import Bid from './bid.model';
import BuyerProfile from './buyer-profile.model';
import SellerProfile from './seller-profile.model';
import Image from './image.model';
import Accessory from './accessory.model';
import IssueResponse from './issue-response.model';
import Tag from './tag.model';

export default class Listing {
  id!: number;
  sellerProfile!: SellerProfile;
  model!: any;
  manufactureMonth!: number;
  manufactureYear!: number;
  purchaseMonth!: number;
  purchaseYear!: number;
  originalOwner!: boolean;
  priceCents!: number;
  listedPriceCents!: number;
  strikethroughPriceCents!: number;
  desiredNetPayoutCents!: number;
  promotion!: {
    priceCents: number
    type: string
  };
  created!: Date;
  status!: string;
  condition!: string;
  images!: { type: string, image: Image }[];
  listingChallenge!: {
    id: number,
    hour: number,
    minute: number
  };
  accessories!: Accessory[];
  issues!: IssueResponse[];
  tags!: Tag[];
  inventoryNumber!: string;
  externalUrl!: string;
  externalSyncEnabled!: boolean;
  offersAllowed!: boolean;
  autoDeclineOffersUnderCents!: number;
  flagged!: boolean;
  internalNotes!: string;
  sellerNotes!: string;
  autoDuplicateOnSale!: boolean;
  activePricingModel!: string;
  lastPublished!: Date;
  privateListingBuyer?: BuyerProfile;
  auctionInfo!: {
    startDate?: Date,
    endDate?: Date,
    live: boolean,
    ended: boolean,
    startingPriceCents?: number,
    numberOfBids: number,
    highestBid?: Bid,
    nextBidCents?: number,
    estimatedValueRange?: {
      lowerBoundCents: number,
      upperBoundCents: number
    },
    disclaimers: string[],
    essay?: string,
    requestsGuaranteedBid: boolean,
    guarantorBuyerProfile?: BuyerProfile,
    guaranteedBidCents?: number,
    hasReserve: boolean,
    reserveCents?: number
  }

  get priceDollars(): string {
    let ps = "$" + this.priceCents;
    return ps.substring(0, ps.length-2) + ".00";
  }

  get displayCondition() {
    return capitalize(this.condition.replace('_', ' '));
  }

  get displayCreated() {
    return formatDate(this.created, 'medium', 'en-US');
  }

  get displayStatus() {
    return capitalize(this.status.replace('_', ' '));
  }

  get displayPayoutMethodStatus() {
    return capitalize(this.sellerProfile.payoutAccount.verificationStatus);
  }

  get displayVerificationStatus() {
    return capitalize(this.sellerProfile.payoutAccount.payoutMethodStatus);
  }

  get displaySeller() {
    return `${this.sellerProfile.user.givenName} ${this.sellerProfile.user.familyName} (${this.sellerProfile.user.email})`;
  }

  get displayName() {
    var displayName = ""
    if (this.manufactureYear != null) {
      displayName += `${this.manufactureYear} `
    }
    displayName += `${this.model.displayName}`
    return displayName
  }

  get displayTags() {
    return this.tags.map(tag => {return tag.displayValue}).join(", ");
  }

  canApproveOrReject(): boolean {
    return this.status === "PENDING_REVIEW";
  };

  canArchive(): boolean {
    if (["ARCHIVED", "ORDERED"].includes(this.status)) {
      return false;
    }

    if (this.activePricingModel === 'AUCTION' && this.status === "PUBLISHED") {
      return !this.auctionInfo?.live;
    }

    return true;
  };

  canUnarchive(): boolean {
    return this.status === "ARCHIVED"
  };

  isPublic(): boolean {
    if (this.activePricingModel === 'FIXED_PRICE') {
      return this.lastPublished != null && this.lastPublished != undefined;
    } else if (this.activePricingModel === 'AUCTION') {
      return this.auctionInfo.live || this.auctionInfo.ended
    } else {
      return false
    }
  }
}
