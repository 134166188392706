import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import SellerProfile from 'src/app/models/seller-profile.model';
import SellerLevel from '../models/seller-level.model';

@Injectable({
  providedIn: 'root'
})
export class SellersService {
  constructor(private http: HttpClient) { }

  public getSellerProfiles(type: string | null, sortKey: string | null): Observable<SellerProfile[]> {
    var path = `/marketplace/seller-profiles?expand[]=user`;
    if (!!type) {
      path += `&type=${type}`
    }
    if (!!sortKey) {
      path += `&sort=${sortKey}`
    }
    return this.http.get<SellerProfile[]>(environment.apiUrl + path).pipe(
      map(sellers => sellers.map(s => Object.assign(new SellerProfile(), s)))
    );
  }

  public getSellerProfile(sellerId: number): Observable<SellerProfile> {
    return this.http.get<SellerProfile[]>(environment.apiUrl
        + `/marketplace/seller-profiles/${sellerId}?expand[]=user&expand[]=payoutAccount&expand[]=shippingAddress`)
        .pipe(map(seller => Object.assign(new SellerProfile(), seller)));
  }

  public updateSellerProfile(sellerId: number, params: any) {
    return this.http.patch(environment.apiUrl + `/marketplace/seller-profiles/${sellerId}`, params)
  }

  public getSellerLevels(): Observable<SellerLevel[]> {
    return this.http.get<SellerLevel[]>(environment.apiUrl + `/marketplace/seller-levels`).pipe(
      map(sellerLevel => Object.assign(new SellerLevel(), sellerLevel))
    );
  }
}
