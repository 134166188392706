import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { OffersService } from 'src/app/_services/offers.service';
import { OrdersService } from 'src/app/_services/orders.service';
import { ToolbarService, ToolbarController, TitlePathElement } from 'src/app/_services/toolbar.service';
import Order from 'src/app/models/order.model';
import Offer from "src/app/models/offer.model";
import { ShipmentsService } from 'src/app/_services/shipments.service';
import { pluck } from 'rxjs/operators';
import { FormControl, Validators } from '@angular/forms';
import { DeleteDialog } from 'src/app/common/delete-dialog/delete-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import Return from 'src/app/models/return.model';
import { ReturnsService } from 'src/app/_services/returns.service';
import { clientNames } from 'src/app/models/clients'
import { ChangeOrderAddressDialogComponent } from './change-order-address-dialog/change-order-address-dialog.component';
import Shipment from 'src/app/models/shipment.model';

@Component({
  selector: 'app-orders-detail',
  templateUrl: './orders-detail.component.html',
  styleUrls: ['./orders-detail.component.scss']
})
export class OrdersDetailComponent implements OnInit, OnDestroy, ToolbarController {
  clientNames: object;

  order!: Order | null;
  offer!: Offer | null;
  _orderId: number | null = null;
  return!: Return | null;
  rationale: string = '';
  titlePath = new BehaviorSubject<TitlePathElement[]>([{ title: "Orders", path: ['/marketplace', 'orders'] }]);
  title = new BehaviorSubject<string>("?");

  isPerformingAction = () => {
    return this.isExtendingDeadline 
        || this.isFinalizing
        || this.isRejecting
        || this.isConfirming
        || this.isDeclining
        || this.isCancelling
        || this.isFlagging
        || this.isMarkingAsAuthentic 
        || this.isMarkingAsInauthentic 
        || this.isMarkingInboundShipmentAsInTransit
        || this.isMarkingInboundShipmentAsDelivered 
        || this.isGeneratingOutboundShipment 
        || this.isAttachingTrackingNumber
        || this.isMarkingOutboundShipmentAsInTransit
        || this.isMarkingOutboundShipmentAsDelivered
        || this.isSavingNotes
        || this.isSavingPayoutInfo
  }
  isExtendingDeadline = false
  isFinalizing = false
  isRejecting = false
  isConfirming = false
  isDeclining = false;
  isCancelling = false
  isFlagging = false
  isMarkingAsAuthentic = false
  isMarkingAsInauthentic = false
  isMarkingInboundShipmentAsInTransit = false
  isMarkingInboundShipmentAsDelivered = false
  isGeneratingOutboundShipment = false
  isAttachingTrackingNumber = false
  isMarkingOutboundShipmentAsInTransit = false
  isMarkingOutboundShipmentAsDelivered = false
  isEditingNotes = false
  isSavingNotes = false
  isEditingPayoutInfo = false;
  isSavingPayoutInfo = false;

  commissionRate = new FormControl();
  sellerFee = new FormControl();
  minTotalFee = new FormControl();
  internalNotes = new FormControl();

  outboundShipmentShipDate = new FormControl(new Date());
  outboundShipmentCarrier = new FormControl('FEDEX');
  outboundShipmentLabelFormat = new FormControl('STOCK_4X6');
  outboundShipmentSpecialServices = new FormControl(['SIGNATURE_REQUIRED']);

  outboundShipmentTrackingNumber = new FormControl('', [Validators.required, Validators.minLength(10)]);
  
  constructor(
    private toolbarService: ToolbarService,
    private route: ActivatedRoute,
    private ordersService: OrdersService,
    private offersService: OffersService,
    private shipmentsService: ShipmentsService,
    private returnsService: ReturnsService,
    private router: Router,
    public dialog: MatDialog,
    ) {
      this.outboundShipmentCarrier.disable();

      this.clientNames = clientNames;

      this.route.params.pipe(
        pluck("orderId")
      ).subscribe({
        next: orderId => {
          if (this._orderId == orderId) {
            return;
          }
  
          this._orderId = orderId;
          this.title.next(orderId.toString());
          this.reloadOrder();
          this.reloadOffer();
          this.reloadReturn();
        }
      })
    }

    private reloadOrder() {
      this.order = null;
      this.ordersService.getOrder(this._orderId!).subscribe({
        next: (order: Order) => {
          this.order = order;
          this.reloadOffer();
        },
        error: (error: any) => {
          console.log(error);
        }
      });
    }

    private reloadOffer() {
      this.offer = null;
      
      if (this.order == null) {
        return;
      }

      this.offersService.getOffers(1, 0, null, this.order.listing.id, null, this.order.buyerProfile.id, null, null, null, null).subscribe({
          next: (offerData: any) => {
            this.offer = offerData.data[0];
          },
          error: (error: any) => {
            console.log(error);
          }
      });
    }

    private reloadReturn() {
      this.returnsService.getReturns(null, null, this._orderId, null, null, -1, 0).subscribe({
        next: (response: any) => {
          this.return = response.data.find((r: Return) => r.status != 'CANCELLED')
        },
        error: (error: any) => {
          console.log(error);
        }
      });
    }
    
    ngOnInit(): void {
      this.toolbarService.setController(this);
    }
    
    ngOnDestroy(): void {
      this.toolbarService.removeController(this);
    }

    markAsAuthentic(): void {
      this.dialog.open(DeleteDialog, {
        width: '20vw',
        data: {action: 'verify', name: 'this order', onClickConfirm: (callback: () => void) => this.verifyOrder(true, callback)}
      })
    }

    markAsInauthentic(): void {
      this.dialog.open(DeleteDialog, {
        width: '20vw',
        data: {action: 'reject', name: 'this order', onClickConfirm: (callback: () => void) => this.verifyOrder(false, callback)}
      })
    }

    private verifyOrder(isAuthentic: boolean, callback: () => void) {
      if (isAuthentic) {
        this.isMarkingAsAuthentic = true
      } else {
        this.isMarkingAsInauthentic = true
      }
      this.ordersService.verifyOrder(this._orderId!, isAuthentic).subscribe({
        next: () => {
          window.location.reload()
        },
        error: (error: any) => {
          console.log(error);
          this.isMarkingAsAuthentic = false
          this.isMarkingAsInauthentic = false
          callback()
        }
      });
    }

    markInboundShipmentAsInTransit(): void {
      this.isMarkingInboundShipmentAsInTransit = true
      this.shipmentsService.markAsInTransit(this.order!.receiptShipment.id).subscribe({
        next: () => {
          window.location.reload()
        },
        error: (error: any) => {
          console.log(error);
          this.isMarkingInboundShipmentAsInTransit = false
        }
      });
    }

    markInboundShipmentAsDelivered(): void {
      this.isMarkingInboundShipmentAsDelivered = true
      this.shipmentsService.markAsReceived(this.order!.receiptShipment.id).subscribe({
        next: () => {
          window.location.reload()
        },
        error: (error: any) => {
          console.log(error);
          this.isMarkingInboundShipmentAsDelivered = false
        }
      });
    }

    generateOutboundShipment(): void {
      this.isGeneratingOutboundShipment = true
      var params: any = {
        sendingAddress: -1,
        receivingAddress: this.order!.deliveryAddress.id,
        orderId: this.order!.id,
        shipDate: this.outboundShipmentShipDate.value!.toLocaleDateString('en-CA'),
        carrier: this.outboundShipmentCarrier.value,
        specialServices: this.outboundShipmentSpecialServices.value,
        shippingLabelFormat: this.outboundShipmentLabelFormat.value
      }
      this.shipmentsService.createShipment(params).subscribe({
        next: (shipment: Shipment) => {
          this.ordersService.updateOrder(this.order!.id, {
            deliveryShipment: shipment.id
          }).subscribe({
            next: () => {
              window.location.reload()
            },
            error: (error: any) => {
              console.log(error);
              this.isGeneratingOutboundShipment = false
            }
          });
        },
        error: (error: any) => {
          console.log(error);
          this.isGeneratingOutboundShipment = false
        }
      });
    }

    attachTrackingNumber(): void {
      this.isAttachingTrackingNumber = true
      var params: any = {
        sendingAddress: -1,
        receivingAddress: this.order!.deliveryAddress.id,
        orderId: this.order!.id,
        carrier: this.outboundShipmentCarrier.value,
        trackingNumber: this.outboundShipmentTrackingNumber.value
      }
      this.shipmentsService.createShipment(params).subscribe({
        next: (shipment: Shipment) => {
          this.ordersService.updateOrder(this.order!.id, {
            deliveryShipment: shipment.id
          }).subscribe({
            next: () => {
              window.location.reload()
            },
            error: (error: any) => {
              console.log(error);
              this.isAttachingTrackingNumber = false
            }
          });
        },
        error: (error: any) => {
          console.log(error);
          this.isAttachingTrackingNumber = false
        }
      });
    }

    markOutboundShipmentAsInTransit(): void {
      this.isMarkingOutboundShipmentAsInTransit = true
      this.shipmentsService.markAsInTransit(this.order!.deliveryShipment.id).subscribe({
        next: () => {
          window.location.reload()
        },
        error: (error: any) => {
          console.log(error);
          this.isMarkingOutboundShipmentAsInTransit = false
        }
      });
    }

    markOutboundShipmentAsDelivered(): void {
      this.isMarkingOutboundShipmentAsDelivered = true
      this.shipmentsService.markAsReceived(this.order!.deliveryShipment.id).subscribe({
        next: () => {
          window.location.reload()
        },
        error: (error: any) => {
          console.log(error);
          this.isMarkingOutboundShipmentAsDelivered = false
        }
      });
    }

    extendDeadline(): void {
      this.isExtendingDeadline = true
      this.ordersService.extendOrder(this._orderId!).subscribe({
        next: () => {
          window.location.reload()
        },
        error: (error: any) => {
          console.log(error);
          this.isExtendingDeadline = false
        }
      });
    }

    finalize(): void {
      this.isFinalizing = true
      this.ordersService.finalizeOrder(this._orderId!).subscribe({
        next: () => {
          window.location.reload()
        },
        error: (error: any) => {
          console.log(error);
          this.isFinalizing = false
        }
      });
    }

    reject(): void {
      this.isRejecting = true
      this.dialog.open(DeleteDialog, {
        width: '20vw',
        data: {action: 'reject', name: 'this order', onClickConfirm: (callback: () => void) => this.rejectOrder(callback)}
      })
    }

    private rejectOrder(callback: () => void) {
      this.ordersService.rejectOrder(this._orderId!).subscribe({
        next: () => {
          window.location.reload()
        },
        error: (error: any) => {
          console.log(error);
          this.isRejecting = false
          callback()
        }
      });
    }

    confirm(): void {
      this.isConfirming = true
      this.ordersService.confirmOrder(this._orderId!, true).subscribe({
        next: () => {
          window.location.reload()
        },
        error: (error: any) => {
          console.log(error);
          this.isConfirming = false
        }
      });
    }

    decline(): void {
      this.isDeclining = true
      this.ordersService.confirmOrder(this._orderId!, false).subscribe({
        next: () => {
          window.location.reload()
        },
        error: (error: any) => {
          console.log(error);
          this.isDeclining = false
        }
      });
    }

    cancel(): void {
      this.isCancelling = true
      this.dialog.open(DeleteDialog, {
        width: '20vw',
        data: {action: 'cancel', name: 'this order', onClickConfirm: (callback: () => void) => this.cancelOrder(callback)}
      })
    }

    private cancelOrder(callback: () => void) {
      this.ordersService.cancelOrder(this._orderId!).subscribe({
        next: () => {
          window.location.reload()
        },
        error: (error: any) => {
          console.log(error);
          this.isCancelling = false
          callback()
        }
      });
    }

    onFlag(flagged: boolean): void {
      this.isFlagging = true
      this.ordersService.updateOrder(this._orderId!, { flagged: flagged }).subscribe({
        next: () => {
          this.isFlagging = false
          this.order!.flagged = flagged
        },
        error: error => {
          console.log(error);
          this.isFlagging = false
        }
      });
    }  

    onViewPaymentInStripe(): void {
      window.open(this.order?.paymentTransaction.stripeDashboardLink, "_blank");
    }

    onViewHoldInStripe(): void {
      window.open(this.order?.holdTransaction?.stripeDashboardLink, "_blank");
    }

    startEditingPayoutInfo() {
      this.isEditingPayoutInfo = true
      this.commissionRate.setValue(this.order!.commissionRateBips / 100.0)
      this.sellerFee.setValue(this.order!.sellerFeeCents / 100.0)
      this.minTotalFee.setValue(this.order!.minTotalFeeCents / 100.0)
    }

    cancelEditingPayoutInfo() {
      this.isEditingPayoutInfo = false
    }

    savePayoutInfoEdits() {
      this.isSavingPayoutInfo = true
      this.commissionRate.disable()
      this.sellerFee.disable()
      this.minTotalFee.disable()
      this.ordersService.updateOrder(this._orderId!, { 
        commissionRateBips: Math.round(this.commissionRate.value * 100), 
        sellerFeeCents: Math.round(this.sellerFee.value * 100),
        minTotalFeeCents: Math.round(this.minTotalFee.value * 100),
      }).subscribe({
        next: () => {
          this.isSavingPayoutInfo = false
          this.isEditingPayoutInfo = false
          this.internalNotes.enable()
          this.order!.commissionRateBips = this.commissionRate.value * 100
          this.order!.sellerFeeCents = this.sellerFee.value * 100
          this.order!.minTotalFeeCents = this.minTotalFee.value * 100
          this.order!.payoutAmountCents = this.order!.salePriceCents - this.Math.max(this.order!.salePriceCents * (this.commissionRate.value / 100) - (this.sellerFee.value * 100), this.minTotalFee.value * 100)
        },
        error: error => {
          console.log(error);
          this.isSavingPayoutInfo = false
          this.commissionRate.enable()
          this.sellerFee.enable()
          this.minTotalFee.enable()
        }
      });
    }

    startEditingNotes() {
      this.isEditingNotes = true
      this.internalNotes.setValue(this.order?.internalNotes)
    }

    cancelEditingNotes() {
      this.isEditingNotes = false
      this.internalNotes.reset()
    }

    saveNotesEdits() {
      this.isSavingNotes = true
      this.internalNotes.disable()
      this.ordersService.updateOrder(this._orderId!, { internalNotes: this.internalNotes.value }).subscribe({
        next: () => {
          this.isSavingNotes = false
          this.isEditingNotes = false
          this.internalNotes.enable()
          this.order!.internalNotes = this.internalNotes.value
        },
        error: error => {
          console.log(error);
          this.isSavingNotes = false
          this.internalNotes.enable()
        }
      });
    }

    onEditDeliveryAddressPressed(): void {
      this.dialog.open(ChangeOrderAddressDialogComponent, {
        maxWidth: 440,
        data: {
          order: this.order,
          onAddressChanged: () => {
            this.reloadOrder();
          }
        }
      })
    }

    // For template.
    get Math() {
      return Math;
    }
  }
  