<div class="d-flex flex-column h-100">
  <mat-card class="m-2">
    <mat-card-content class="d-flex flex-md-row flex-column">
        <mat-form-field *ngIf="!highlightsMode" appearance="outline" class="flex-fill mx-1">
            <mat-label>Status</mat-label>
            <mat-select [(ngModel)]='statusFilter' name='Status' (selectionChange)="onSelectStatus($event)">
                <mat-option>-</mat-option>
                <mat-option *ngFor="let status of getPurchaseRequestStatuses()" [value]="getStatusIdFor(status)">
                    {{status}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="!highlightsMode" appearance="outline" class="mx-1 flex-fill">
          <mat-label>Model</mat-label>
          <input matInput placeholder="Add models..." class="w-100 h-100" [formControl]="modelFilterControl" [matAutocomplete]="modelFilterAutocomplete">
          <button *ngIf="modelFilterControl.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearModelSelection()">
            <mat-icon>close</mat-icon>
          </button>
          <mat-autocomplete #modelFilterAutocomplete="matAutocomplete" (optionSelected)="onModelClicked($event)">
            <mat-option *ngFor="let model of modelSearchResults | async" [value]="model">
              <div class="w-100 h-100 d-flex">
                <img [src]="model.images?.[0]?.url" class="image" *ngIf="model.images?.[0]?.url" style="max-height: 40px;">
                <span class="title">
                  {{model.brand.displayName ?? model.brand.name}} {{model.displayName ?? model.name}} {{model.referenceNumber}}
                </span>
              </div>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <div class="labeled-box" *ngIf="filterModelIds.length > 0 && !highlightsModeControl.value">
          <div class="label">
            Filter Models
          </div>
          <mat-chip-set>
              <model-chip *ngFor="let model of filterModels" [model]="model"></model-chip>
          </mat-chip-set>
        </div>
        <div appearance="outline"  class="mx-1 flex-fill">
          <mat-checkbox [formControl] = "highlightsModeControl" (ngModelChange)="onHighlightsToggle()">
            Highlights View
          </mat-checkbox>
        </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="mx-2 mb-2 p-0 flex-fill d-flex flex-column overflow-scroll">
    <div class="flex-fill position-relative overflow-scroll">
      <div *ngIf="isLoadingPRs" class="w-100 h-100 position-absolute d-flex align-items-center justify-content-center" style="z-index: 1;">
        <div *ngIf="purchaseRequests.length > 0" class="w-100 h-100 position-absolute" style="background-color: rgba(0, 0, 0, 0.1);"></div>
        <mat-spinner diameter="40" class="loading-spinner"></mat-spinner>
      </div>
      <div class="h-100 w-100 overflow-scroll">
        <table mat-table matSort [dataSource]="dataSource" (matSortChange)="onSort($event)" class="w-100">
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Id"> Id </th>
            <td mat-cell *matCellDef="let purchaseRequest"> {{purchaseRequest.id}} </td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let purchaseRequest">
              <div class="badge-narrow" [ngClass]="getBadge(purchaseRequest)">
                  {{ purchaseRequest.statusEng }}
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="offers">
            <th mat-header-cell *matHeaderCellDef>Responses (Active/Total)</th>
            <td mat-cell *matCellDef="let purchaseRequest">
              {{getPurchaseRequestOffers(purchaseRequest)}}
            </td>
          </ng-container>
          <ng-container matColumnDef="buyerProfile">
            <th mat-header-cell *matHeaderCellDef> Buyer </th>
            <td mat-cell *matCellDef="let purchaseRequest">
              <buyer-chip [buyer]="purchaseRequest.buyerProfile" [short]="true"></buyer-chip>
            </td>
          </ng-container>
          <ng-container matColumnDef="model">
            <th mat-header-cell *matHeaderCellDef> Model </th>
            <td mat-cell *matCellDef="let purchaseRequest">
              <model-chip [model]="purchaseRequest.model" trim="40" extraShort="true"></model-chip>
            </td>
          </ng-container>
          <ng-container matColumnDef="price">
            <th mat-header-cell *matHeaderCellDef> Price </th>
            <td mat-cell *matCellDef="let purchaseRequest">
              {{ purchaseRequest.priceDollars }}
            </td>
          </ng-container>
          <ng-container matColumnDef="expiration">
            <th mat-header-cell *matHeaderCellDef mat-sort-header  sortActionDescription="Sort by Expiration"> Expiration </th>
            <td mat-cell *matCellDef="let purchaseRequest"> {{purchaseRequest.expiration | date:'medium' }} </td>
          </ng-container>
          <ng-container matColumnDef="created">
            <th mat-header-cell *matHeaderCellDef mat-sort-header  sortActionDescription="Sort by Created"> Created </th>
            <td mat-cell *matCellDef="let purchaseRequest"> {{purchaseRequest.created | date:'medium' }} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let purchaseRequest; columns: displayedColumns;" [routerLink]="['/marketplace/purchase-requests/', purchaseRequest.id]">
        </table>
      </div>
    </div>
    <mat-paginator [length]="length"
                [pageIndex]="page"
                [pageSize]="pageSize"
                [pageSizeOptions]="pageSizeOptions"
                aria-label="Select page">
    </mat-paginator>
  </mat-card>
  <button mat-fab class="fab" (click)="addButtonPressed()">
    <mat-icon>add</mat-icon>
  </button>
</div>
