<div class="container" *ngIf="list && !isLoading else loading">
  <mat-card>
    <mat-card-title>
      <div class="d-flex justify-content-between">
        <span>Details</span>
        <span>
                    <button mat-raised-button color="primary" (click)="editButtonPressed()"
                            *ngIf="!isEditing">Edit</button>
                    <button mat-raised-button color="warn" class="mx-2" (click)="deleteButtonPressed()"
                            *ngIf="!isEditing">Delete</button>

                    <button mat-raised-button color="primary" style="background: green" (click)="saveButtonPressed()"
                            [class.spinner]="isSaving" [disabled]="isSaving" *ngIf="isEditing">Save</button>
                    <button mat-raised-button color="warn" class="mx-2" (click)="cancelButtonPressed()"
                            *ngIf="isEditing" [disabled]="isSaving">Cancel</button>
                </span>
      </div>
    </mat-card-title>
    <mat-card-content>
      <ng-container *ngIf="!isEditing">
        <div class="labeled-box">
          <div class="label">Name</div>
          {{ list.name }}
        </div>
        <div class="labeled-box">
          <div class="label">Type</div>
          {{ list.displayType }}
        </div>
        <div class="labeled-box" *ngIf="list.type == 'MODEL' || list.type == 'LISTING'">
          <div class="label">Inventory sensitive</div>
          {{ list.inventorySensitive ? 'True' : 'False' }}
        </div>
        <div class="labeled-box">
          <div class="label">Auctions Only</div>
          {{ list.isAuctionsOnly ? 'True' : 'False' }}
        </div>
        <div class="labeled-box">
          <div class="label">Created</div>
          {{ list.created | date: 'medium' }}
        </div>
        <div class="labeled-box">
          <div class="label">Last updated</div>
          {{ list.updated | date: 'medium' }}
        </div>
        <div class="labeled-box" *ngIf="isAutoList">
          <div class="label">Max Length</div>
          {{list.maxLength}}
        </div>
        <div class="labeled-box" *ngIf="isAutoList">
          <div class="label">Sort Criteria</div>
          {{list.sortCriteria}}
        </div>
      </ng-container>
      <div *ngIf="isEditing" class="d-flex flex-row" style="gap: 8px">
        <mat-form-field appearance="outline">
          <mat-label>Name</mat-label>
          <input matInput type="string" placeholder="New In 09/22" [formControl]="name">
        </mat-form-field>
        <mat-checkbox [formControl]="inventorySensitive" *ngIf="list.type == 'MODEL' || list.type == 'LISTING'"
                      class="mx-4">Inventory sensitive
        </mat-checkbox>
        <ng-container *ngIf="isAutoList">
          <mat-form-field appearance="outline">
            <mat-label>Max Length</mat-label>
            <input matInput type="string" [formControl]="maxLength">
          </mat-form-field>
          <mat-form-field appearance="outline" style="min-width: 320px;">
            <mat-label>Sort Criteria</mat-label>
            <mat-select [(value)]="list.sortCriteria">
              <mat-option *ngFor="let criteria of sortCriteriaOptions" [value]="criteria" [disabled]="list.type != 'MODEL' && list.type != 'LISTING' && criteria == 'RECOMMENDED_FOR_YOU'">
                {{criteria}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <ng-container *ngIf="isAutoList">
      <mat-card-title>
        <div class="d-flex justify-content-between">
          <span>Prefix Items</span>
        </div>
      </mat-card-title>
      <mat-card-content id="prefix container">
      <ng-container *ngIf="isEditing else displayPrefix">
        <div class="d-flex justify-content-between">
          <mat-form-field appearance="outline" class="flex-fill">
            <input matInput type="string" placeholder=" " [formControl]="prefix">
          </mat-form-field>
        </div>
        <div class="warning" *ngIf="errorMessage.length > 0">
          {{errorMessage}}
        </div>
      </ng-container>
      <ng-template #displayPrefix>
        <div class="labeled-box">
          <div class="label">Prefix</div>
          {{ list?.parsedFilters?.prefix ?? "" }}
        </div>
      </ng-template>
    </mat-card-content>

      <mat-card-title>
        <div class="d-flex justify-content-between">
          <span>Filters</span>
          <span *ngIf="isEditing">
                        <button mat-raised-button color="primary" (click)="addFilterButtonPressed()">Add</button>
                    </span>
        </div>
      </mat-card-title>
      <mat-card-content id="filterContainer">
        <ng-container *ngIf="isEditing else displayFilters">
          <table mat-table [dataSource]="filterData" class="w-100">

            <ng-container matColumnDef="subject">
              <th mat-header-cell *matHeaderCellDef> Subject </th>
              <td mat-cell *matCellDef="let filter">
                <mat-form-field appearance="outline" class="flex-fill">
                  <mat-label>{{filter.subject}}</mat-label>
                  <mat-select (selectionChange)="onSubjectChange($event, filter)">
                    <mat-option *ngIf="filter.subject != 'EMPTY'">{{filter.subject}}</mat-option>
                    <mat-option *ngFor="let subject of list?.availableSubjects ?? []" [value]="subject">
                      {{subject}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef> Type </th>
              <td mat-cell *matCellDef="let filter">
                <mat-form-field appearance="outline" class="flex-fill">
                  <mat-label>{{filter.type}}</mat-label>
                  <mat-select (selectionChange)="onTypeChange($event, filter)">
                    <mat-option *ngFor="let type of filter.validTypes" [value]="type">
                      {{type}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="values">
              <th mat-header-cell *matHeaderCellDef>Values</th>
              <td mat-cell *matCellDef="let filter">
                <mat-form-field class="vals-chip-list">
                  <mat-chip-grid #filterValuesChipGrid aria-label="Filter values">
                    <mat-chip-row *ngFor="let value of filter.displayValues;"
                              (removed)="removeFilterValue(value, filter)" [editable]="false">
                      {{value.name}}
                      <button matChipRemove [attr.aria-label]="'remove ' + value">
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </mat-chip-row>
                  </mat-chip-grid>
                  <input placeholder=""
                        #fvInput
                        [formControl]="filterValueControllers.get(filter._localId)"
                        [matChipInputFor]="filterValuesChipGrid"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        [matChipInputAddOnBlur]="addOnBlur"
                        (matChipInputTokenEnd)="addFilterValue($event, filter)"
                        [matAutocomplete]="filterValuesAutoComplete">
                  <mat-autocomplete #filterValuesAutoComplete="matAutocomplete" (optionSelected)="autocompleteSelected($event, filter, fvInput)">
                    <mat-option *ngFor="let val of filterValueObservers.get(filter._localId) | async" [value]="val">
                      {{val}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="delete">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let filter">
                <button mat-raised-button color="primary" (click)="removeFilterButtonPressed(filter)">-</button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="filterDisplayedColumnsEd; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: filterDisplayedColumnsEd;"></tr>
          </table>
        </ng-container>

        <ng-template #displayFilters>
          <table mat-table [dataSource]="filterData" class="vals-chip-list">
            <ng-container matColumnDef="subject">
              <th mat-header-cell *matHeaderCellDef> Subject </th>
              <td mat-cell *matCellDef="let filter">{{filter.subject}}</td>
            </ng-container>

            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef> Type </th>
              <td mat-cell *matCellDef="let filter">{{filter.type}}</td>
            </ng-container>

            <ng-container matColumnDef="values">
              <th mat-header-cell *matHeaderCellDef>Values</th>
              <td mat-cell *matCellDef="let filter">
                <mat-chip-listbox>
                  <mat-chip *ngFor="let value of filter.displayValues;">
                    {{value.name}}
                  </mat-chip>
                </mat-chip-listbox>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="filterDisplayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: filterDisplayedColumns;"></tr>
          </table>
        </ng-template>
      </mat-card-content>
    </ng-container>
    <ng-container *ngIf="!isAutoList || !isEditing">
      <mat-card-title>
        <div class="d-flex justify-content-between">
          <span>Items</span>
          <ng-container *ngIf="isAutoList">
            <span style="width: 400px; text-align: left;">{{loadMessage}}</span>
          </ng-container>
          <span style="width: 400px; text-align: right;">
                        <ng-container *ngIf="!isAutoList && (isEditing || isSaving)">
                            <model-select *ngIf="list.type == 'MODEL'" [control]="addItem" label="Add a model"
                                          placeholder="5115J" style="font-size: 10pt;"></model-select>
                            <listing-select *ngIf="list.type == 'LISTING'" [control]="addItem" label="Add a listing"
                                            placeholder="5115J" style="font-size: 10pt;"></listing-select>
                            <brand-select *ngIf="list.type == 'BRAND'" [control]="addItem" label="Add a brand"
                                          style="font-size: 10pt;"></brand-select>
                            <collection-select *ngIf="list.type == 'COLLECTION'" [control]="addItem"
                                               label="Add a collection" placeholder="Heavy hitters"
                                               style="font-size: 10pt"></collection-select>
                            <feature-select *ngIf="list.type == 'FEATURE'" [control]="addItem" label="Add a feature"
                                            placeholder="Heavy hitters" style="font-size: 10pt"></feature-select>
                            <article-select *ngIf="list.type == 'ARTICLE'" [control]="addItem" label="Add an article"
                                            placeholder="This Week's Best: Submariner Alternatives"
                                            style="font-size: 10pt"></article-select>
                        </ng-container>
                    </span>
        </div>
      </mat-card-title>
      <mat-card-content id="scrollContainer"
                        style="overflow-x: auto; margin: -40px -16px -16px -16px; padding: 44px 16px 28px 16px"
                        (wheel)="onWheel($event)">
        <ng-container *ngIf="errorMessage != ''">
          <span class="warning">
            {{errorMessage}}
          </span>
        </ng-container>
        <ng-container *ngIf="!isEditing && !isSaving else dragToReorderList">
          <ng-container *ngIf="list.type == 'MODEL'">
            <div *ngFor="let model of list.items"
                 style="margin-right: 4px; margin-left: 4px; margin-top: 2px; display: inline-block;">
              <model-card [model]="model"></model-card>
            </div>
          </ng-container>
          <ng-container *ngIf="list.type == 'LISTING'">
            <div *ngFor="let listing of list.items"
                 style="margin-right: 4px; margin-left: 4px; margin-top: 6px; vertical-align:top; display: inline-block;">
              <listing-card [listing]="listing"></listing-card>
            </div>
          </ng-container>
          <ng-container *ngIf="list.type == 'BRAND'">
            <div *ngFor="let brand of list.items"
                 style="margin-right: 4px; margin-left: 4px; margin-top: 2px; display: inline-block;">
              <brand-card [brand]="brand"></brand-card>
            </div>
          </ng-container>
          <ng-container *ngIf="list.type == 'COLLECTION'">
            <div *ngFor="let collection of list.items"
                 style="margin-right: 4px; margin-left: 4px; margin-top: 2px; display: inline-block;">
              <collection-card [collection]="collection"></collection-card>
            </div>
          </ng-container>
          <ng-container *ngIf="list.type == 'FEATURE'">
            <div *ngFor="let feature of list.items"
                 style="margin-right: 4px; margin-left: 4px; margin-top: 2px; display: inline-block;">
              <feature-card [feature]="feature"></feature-card>
            </div>
          </ng-container>
          <ng-container *ngIf="list.type == 'ARTICLE'">
            <div *ngFor="let article of list.items"
                 style="margin-right: 4px; margin-left: 4px; margin-top: 2px; display: inline-block;">
              <article-card [article]="article"></article-card>
            </div>
          </ng-container>
        </ng-container>
        <ng-template #dragToReorderList>
            <sortable-list [list]="list"></sortable-list>
        </ng-template>
      </mat-card-content>
    </ng-container>
  </mat-card>
</div>
<ng-template #loading>
  <div class="d-flex flex-column align-items-center m-5">
    <mat-spinner diameter="40" class="loading-spinner"></mat-spinner>
  </div>
</ng-template>
