import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import Model from 'src/app/models/model.model';
import { SearchService } from 'src/app/_services/search.service';

@Component({
  selector: 'model-select',
  templateUrl: './model-select.component.html',
})
export class ModelSelectComponent implements OnInit {
    @Input('control') control!: FormControl;
    @Input('required') required: boolean = false;
    @Input('label') label: string = "Model";
    @Input('placeholder') placeholder: string = "Pick a model";
    
    autocompleteResults: Model[] = []

    constructor(private searchService: SearchService) {

    }

    ngOnInit(): void {
        this.control.valueChanges.pipe(
            debounceTime(100),
            distinctUntilChanged()
        ).subscribe({
            next: value => {
                if (value == null) {
                    return
                }
                if (String(value) !== value) {
                    return
                }

                this.searchService.search(value, [], ["MODEL"]).subscribe({
                    next: results => {
                        this.autocompleteResults = results.results.map(r => this.toModel(r.object))
                    }
                })
            }
        })
    }

    displayModel(model: Model): string {
        if (model == null) {
            return ""
        }
        return model.displayName ?? model.name ?? ""
    }

    private toModel(model: any): Model {
        return Object.assign(new Model(), model)
    }
}
