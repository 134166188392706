import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import Order from 'src/app/models/order.model';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {
  constructor(private http: HttpClient) { }

  public getOrders(start: number, status: string | null, buyer: number | null, seller: number | null, client: string | null, flagged: boolean | null, sortKey: string | null): Observable<any> {
    var path = `/marketplace/orders?start=${start}&limit=20&expand[]=listing.model&expand[]=invoice&expand[]=buyerProfile.user`;
    if (!!buyer) {
      path += `&buyerProfile[]=${buyer}`
    }
    if (!!seller) {
      path += `&sellerProfile[]=${seller}`
    }
    if (!!status) {
      path += `&status[]=${status}`;
    }
    if (!!client) {
      path += `&client[]=${client}`;
    }
    if (!!flagged) {
      path += `&flagged=${flagged}`;
    }
    if (!!sortKey) {
      path += `&sort=${sortKey}`
    }
    return this.http.get<Order[]>(environment.apiUrl + path, {observe: 'response'}).pipe(
      map(response => {
        return {
          data: response.body!.map(o => Order.toOrder(o)),
          totalCount:  response.headers.get('X-Total-Count')
        }
      })
    );
  }

  public getOrder(orderId: number): Observable<Order> {
    var path = `/marketplace/orders/${orderId}?`;
    path += 'expand[]=listing.model&';
    path += 'expand[]=listing.sellerProfile.user&';
    path += 'expand[]=deliveryAddress&';
    path += 'expand[]=paymentTransaction&';
    path += 'expand[]=holdTransaction&';
    path += 'expand[]=receiptShipment&';
    path += 'expand[]=deliveryShipment&';
    path += 'expand[]=buyerProfile.user&';
    path += 'expand[]=invoice&';
    path += 'expand[]=confirmedBy&';
    path += 'expand[]=finalizedBy&';
    path += 'expand[]=verifiedBy&';
    path += 'expand[]=verificationReport.intakeReport&';
    path += 'expand[]=verificationReport.authenticationReport&';
    path += 'expand[]=verificationReport.diagnosticReport';

    return this.http.get<Order>(environment.apiUrl + path).pipe(
      map(order => Order.toOrder(order))
    );
  }

  public getMostRecentOrderForListing(listingId: number): Observable<Order | null> {
    var path = `/marketplace/orders?limit=1&expand[]=listing.model&expand[]=invoice&expand[]=buyerProfile.user&listing[]=${listingId}&sort=-created`;
    return this.http.get<Order[]>(environment.apiUrl + path).pipe(
      map(orders => {
        if (orders.length > 0) {
          return orders[0];
        } else {
          return null;
        }
      })
    );
  }

  public updateOrder(ingestionRequestId: number, params: any): Observable<Order> {
    return this.http.patch<Order>(environment.apiUrl + `/marketplace/orders/${ingestionRequestId}`, params);
  }

  public finalizeOrder(orderId: number): Observable<Object> {
    return this.http.post(environment.apiUrl + `/marketplace/orders/${orderId}/finalize`, {});
  }

  public rejectOrder(orderId: number): Observable<Object> {
    return this.http.post(environment.apiUrl + `/marketplace/orders/${orderId}/reject`, {});
  }

  public confirmOrder(orderId: number, isConfirmed: boolean): Observable<Object> {
    return this.http.post(environment.apiUrl + `/marketplace/orders/${orderId}/confirm`, {
      'confirmed': isConfirmed
    });
  }

  public cancelOrder(orderId: number): Observable<Object> {
    return this.http.post(environment.apiUrl + `/marketplace/orders/${orderId}/cancel`, {});
  }

  public verifyOrder(orderId: number, isAuthenticated: boolean) {
    return this.http.post(environment.apiUrl + `/marketplace/orders/${orderId}/verify`, {
      'authenticated': isAuthenticated
    });
  }

  public extendOrder(orderId: number) {
    return this.http.post(environment.apiUrl + `/marketplace/orders/${orderId}/extend`, {});
  }
}
