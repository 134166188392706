import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import PurchaseRequest from '../models/purchase-request.model';
import PurchaseRequestOffer from '../models/purchase-request-offer.model';
import Listing from '../models/listing.model';

@Injectable({
    providedIn: 'root'
})
export class PurchaseRequestService {
  constructor(private http: HttpClient) { }

  PRO_EXPANDS = "expand[]=listing&expand[]=listing.model&expand[]=listing.sellerProfile&expand[]=listing.sellerProfile.user";

  public getPurchaseRequests(ids: number[] | null, buyerProfileIds: number[] | null, modelIds: number[] | null, status: String | null, sortKey: String | null ): Observable<any> {
      var path = "/marketplace/purchase-requests?expand[]=buyerProfile.user&expand[]=model";
      if (!!sortKey) {
        path += `&sort=${sortKey}`;
      }
      if (!!ids) {
        ids.forEach(id => path += `&id[]=${id}`);
      }
      if (!!buyerProfileIds) {
        buyerProfileIds.forEach(bpi => path += `&buyerProfile[]=${bpi}`);
      }
      if (!!modelIds) {
        modelIds.forEach(mid => path += `&model[]=${mid}`);
      }
      if (!!status) {
        path += `&status[]=${status.toUpperCase()}`;
      }
      return this.http.get<PurchaseRequest[]>(environment.apiUrl + path, {observe: 'response'}).pipe(
          map(response => {
            return {
              data: response.body!.map(pr => this.toPR(pr)),
              totalCount:  response.headers.get('X-Total-Count')
            }
          })
        );
  }

  public getPurchaseRequestHighlights(sortKey: String | null) {
    var path = "/marketplace/purchase-requests/highlights?tags[]=GREAT_PRICE&tags[]=BEST_PRICE&expand[]=buyerProfile.user&expand[]=model";
    if (!!sortKey) {
      path += `&sort=${sortKey}`;
    }
    return this.http.get<PurchaseRequest[]>(environment.apiUrl + path, {observe: 'response'}).pipe(
      map(response => {
        return {
          data: response.body!.map(pr => this.toPR(pr)),
          totalCount:  response.headers.get('X-Total-Count')
        }
      })
    );
  }

  public getPurchaseRequest(purchaseRequestId: number, expandBuyerProfile : boolean, expandModel : boolean, expandHold: boolean) : Observable<any> {
      var path = "/marketplace/purchase-requests/" + purchaseRequestId;
      if (expandBuyerProfile || expandModel || expandHold) {
          path = path + "?";
      }

      if (expandBuyerProfile) {
          path = path + "expand[]=buyerProfile.user";
          if (expandModel || expandHold) {
            path = path + "&";
          }
      }
      if (expandModel) {
        path = path + "expand[]=model";
        if (expandHold) {
          path = path + "&";
        }
      }
      if (expandHold) {
        path = path + "expand[]=hold";
      }

      return this.http.get<PurchaseRequest>(environment.apiUrl + path, {observe: 'response'}).pipe(
          map(response =>  this.toPR(response.body)));
  }

  public createPurchaseRequest(template: any) : Observable<PurchaseRequest> {
    var path="/marketplace/purchase-requests";
    return this.http.post<PurchaseRequest>(environment.apiUrl+path, template).pipe(map(response => this.toPR(response)));
  }

  public updatePurchaseRequest(id: number, template: any, expandPurchaseRequest: boolean) : Observable<PurchaseRequest> {
    var path = "/marketplace/purchase-requests/" + id;
    if (expandPurchaseRequest) {
      path = path + "?expand[]=buyerProfile.user&expand[]=model";
    }
    return this.http.patch<PurchaseRequest>(environment.apiUrl+path, template).pipe(map(response => this.toPR(response)));
  }

  public closePurchaseRequest(id: number) : Observable<any> {
    var path="/marketplace/purchase-requests/" + id + "/close";
    return this.http.post<any>(environment.apiUrl+path, null);
  }

  public getPurchaseRequestOffers(ids: number[] | null, purchaseRequestIds: number[] | null, listingIds: number[] | null, expandPurchaseRequest: boolean): Observable<any> {
    var path = "/marketplace/purchase-request-offers?" + this.PRO_EXPANDS;
    if (expandPurchaseRequest) {
      path = path + "&expand[]=purchaseRequest";
    }

    if (!!ids) {
      ids.forEach(id => path += `&id[]=${id}`);
    }
    if (!!purchaseRequestIds) {
      purchaseRequestIds.forEach(pr => path += `&purchaseRequest[]=${pr}`);
    }
    if (!!listingIds) {
      listingIds.forEach(l => path += `&listing[]=${l}`);
    }
    return this.http.get<PurchaseRequestOffer[]>(environment.apiUrl + path, {observe: 'response'}).pipe(
        map(response => {
          return {
            data: response.body!.map(pro => this.toPRO(pro)),
            totalCount:  response.headers.get('X-Total-Count')
          }
        })
      );
  }

  public createPurchaseRequestOffer(template: any) : Observable<PurchaseRequestOffer> {
    var path="/marketplace/purchase-request-offers?" + this.PRO_EXPANDS;
    return this.http.post<PurchaseRequestOffer>(environment.apiUrl+path, template).pipe(map(response => this.toPRO(response)));
  }

  public updatePurchaseRequestOffer(id: number, template: any, expandPurchaseRequest: boolean) : Observable<PurchaseRequestOffer> {
    var path = "/marketplace/purchase-request-offers/" + id + "?" + this.PRO_EXPANDS;
    if (expandPurchaseRequest) {
      path = path + "&expand[]=purchaseRequest";
    }
    return this.http.patch<PurchaseRequestOffer>(environment.apiUrl+path, template).pipe(map(response => this.toPRO(response)));
  }

  public closePurchaseRequestOffer(id: number) : Observable<any> {
    var path="/marketplace/purchase-request-offers/" + id + "/close";
    return this.http.post<any>(environment.apiUrl+path, null);
  }

  private toPR(fetchedPR: any): PurchaseRequest {
      var pr = Object.assign(new PurchaseRequest(), fetchedPR);
      return pr;
  }

  private toPRO(fetchedPRO: any): PurchaseRequestOffer {
    var pr = Object.assign(new PurchaseRequestOffer(), fetchedPRO);
    return pr;
  }

  private toListing(fetchedListing: any): Listing {
    var listing = Object.assign(new Listing(), fetchedListing);
    return listing;
  }
}

