export const environment = {
  production: false,
  apiUrl: "https://api.sandbox.bezel.cloud",
  authClientId: "mGwSgbTwhWBgGWNLDkDkjJKS5EwHP2k1",
  authDomain: "identity.sandbox.bezel.cloud",
  bezelDomain: "https://shop.sandbox.bezel.cloud",
  loupeDomain: "https://loupe.sandbox.bezel.cloud",
  version: 'main-d4c1e9b',
  platformCoreUuid: "1CMOzHEkzgzOqzzPIkiYR9I0oJGJg9Yl@clients",
  dolphinUuid: "WlbS5zxQqz677pvjPYlbL9M2nibKOZNG@clients"
};
