import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import Offer from "../models/offer.model";
import Listing from "../models/listing.model";
import BuyerProfile from "../models/buyer-profile.model";

@Injectable({
  providedIn: 'root'
})
export class OffersService {
  constructor(private http: HttpClient) { }


  public getOffers(pageSize: number, offset: number, status: string | null, listing: number | null, model: number | null, buyer: number | null, client: string | null, apiVersion: number | null, accepted: boolean | null, sortKey: string | null): Observable<any> {
    var path = `/marketplace/offers?offset=${offset}&pageSize=${pageSize}&expand[]=listing.model&expand[]=buyerProfile.user`;
    if (!!listing) {
      path += `&listing[]=${listing}`;
    }
    if (!!model) {
      path += `&model[]=${model}`;
    }
    if (!!buyer) {
      path += `&buyerProfile[]=${buyer}`;
    }
    if (!!status) {
      path += `&status[]=${status}`;
    }
    if (!!client) {
      path += `&client[]=${client}`;
    }
    if (!!apiVersion) {
      path += `&apiVersion[]=${apiVersion}`;
    }
    if (!!accepted) {
      path += `&accepted=${accepted}`;
    }
    if (!!sortKey) {
      path += `&sort=${sortKey}`
    }
    return this.http.get<Offer[]>(environment.apiUrl + path, {observe: 'response'}).pipe(
      map(response => {
        return {
          data: response.body!.map(o => this.toOffer(o)),
          totalCount:  response.headers.get('X-Total-Count')
        }
      })
    );
  }

  public getOffer(offerId: number): Observable<Offer> {
    return this.http.get<Offer>(environment.apiUrl + `/marketplace/offers/${offerId}?expand[]=listing.model&expand[]=buyerProfile.user&expand[]=transaction`).pipe(
      map(offer => this.toOffer(offer))
    );
  }

  public submitOffer(buyerProfileId: number, listingId: number, priceCents: number) {
    return this.http.post(environment.apiUrl + `/marketplace/offers`, {
      'buyerProfile': buyerProfileId,
      'listing': listingId,
      'priceCents': priceCents
    });
  }

  public acceptOffer(offerId: number) {
    return this.http.post(environment.apiUrl + `/marketplace/offers/${offerId}/accept`, {});
  }

  public declineOffer(offerId: number) {
    return this.http.post(environment.apiUrl + `/marketplace/offers/${offerId}/decline`, {});
  }

  public counterOffer(offerId: number, priceCents: number) {
    return this.http.post(environment.apiUrl + `/marketplace/offers/${offerId}/counter`, {
      'priceCents': priceCents
    });
  }

  public extendOffer(offerId: number) {
    return this.http.post(environment.apiUrl + `/marketplace/offers/${offerId}/extend`, {});
  }

  public cancelOffer(offerId: number, keepHold: boolean) {
    return this.http.post(environment.apiUrl + `/marketplace/offers/${offerId}/cancel`, {keepHold: keepHold});
  }

  private toOffer(offer: any): Offer {
    var offer = Object.assign(new Offer(), offer);
    if (offer.listing) {
      offer.listing = Object.assign(new Listing(), offer.listing)
    }
    if (offer.buyerProfile) {
      offer.buyerProfile = Object.assign(new BuyerProfile(), offer.buyerProfile)
    }
    return offer;
  }
}