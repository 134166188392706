<div class="d-flex flex-column h-100">
    <mat-card class="m-2">
        <mat-card-content class="d-flex flex-md-row flex-column">
            <mat-form-field appearance="outline" class="flex-fill mx-1">
                <mat-label>Status</mat-label>
                <mat-select [(ngModel)]='statusFilter' name='Status' (selectionChange)="onSelectStatus($event)">
                    <mat-option *ngFor="let status of allStatuses | keyvalue" [value]="status.key">
                        {{status.value}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <seller-select [valueId]="sellerFilter" (onSelect)="onChangeSellerFilter($event)" class="flex-fill mx-1"></seller-select>
            <mat-form-field appearance="outline" class="flex-fill mx-1">
                <mat-label>Flagged</mat-label>
                <mat-select [(ngModel)]='flaggedFilter' name='Flagged' (selectionChange)="onChangeFlaggedFilter($event)">
                    <mat-option [value]="">-</mat-option>
                    <mat-option [value]="'true'">YES</mat-option>
                    <mat-option [value]="'false'">NO</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" class="flex-fill mx-1">
                <mat-label>Assignee</mat-label>
                <mat-select [(ngModel)]='assigneeFilter' name='Assignee' (selectionChange)="onChangeAssigneeFilter($event)">
                    <mat-option [value]="">-</mat-option>
                    <mat-option [value]="'(null)'">Unassigned</mat-option>
                    <mat-option *ngFor="let user of consoleUsers" [value]="user.uuid">{{ user.displayName }}</mat-option>
                </mat-select>
            </mat-form-field>
        </mat-card-content>
    </mat-card>
    <mat-card class="mx-2 mb-2 p-0 flex-fill d-flex flex-column overflow-scroll">
        <div class="flex-fill position-relative overflow-scroll">
            <div *ngIf="isLoading"
                class="w-100 h-100 position-absolute d-flex align-items-center justify-content-center"
                style="z-index: 1;">
                <div *ngIf="ingestionRequests.length > 0" class="w-100 h-100 position-absolute"
                    style="background-color: rgba(0, 0, 0, 0.1);"></div>
                <mat-spinner diameter="40" class="loading-spinner"></mat-spinner>
            </div>
            <div class="h-100 w-100 overflow-scroll">
                <table mat-table matSort (matSortChange)="onSort($event)" [dataSource]="ingestionRequests"
                    class="w-100">
                    <ng-container matColumnDef="flagged">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let ingestionRequest" style="position: relative;">
                            <a class="nostyle" style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; z-index: 1" [routerLink]="['/marketplace/ingestion-requests/', ingestionRequest.id]"></a>
                            <a class="nostyle" style="position: relative; z-index: 2" [href]="ingestionRequest.url"><mat-icon *ngIf="ingestionRequest.flagged" color="warn">flag</mat-icon></a>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef> Id </th>
                        <td mat-cell *matCellDef="let ingestionRequest" style="position: relative;">
                            <a class="nostyle" style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; z-index: 1" [routerLink]="['/marketplace/ingestion-requests/', ingestionRequest.id]"></a>
                            <a class="nostyle" style="position: relative; z-index: 2" [href]="ingestionRequest.url">{{ingestionRequest.id}}</a>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef> Status </th>
                        <td mat-cell *matCellDef="let ingestionRequest" style="position: relative;">
                            <a class="nostyle" style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; z-index: 1" [routerLink]="['/marketplace/ingestion-requests/', ingestionRequest.id]"></a>
                            <a class="nostyle" style="position: relative; z-index: 2" [href]="ingestionRequest.url">
                                <div class="badge-narrow" [ngClass]="{
                                    'badge-narrow-success': ingestionRequest.status === 'COMPLETE', 
                                    'badge-narrow-error': ingestionRequest.status === 'NEEDS_ATTENTION', 
                                    'badge-narrow-yellow': ingestionRequest.status === 'PENDING_REVIEW'
                                    }">
                                    {{ ingestionRequest.status }}
                                </div>
                            </a>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="url">
                        <th mat-header-cell *matHeaderCellDef> URL </th>
                        <td mat-cell *matCellDef="let ingestionRequest" style="position: relative;">
                            <a class="nostyle" style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; z-index: 1" [routerLink]="['/marketplace/ingestion-requests/', ingestionRequest.id]"></a>
                            <a class="nostyle" style="position: relative; z-index: 2" [routerLink]="['/marketplace/ingestion-requests/', ingestionRequest.id]">{{ingestionRequest.url}}</a>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="seller">
                        <th mat-header-cell *matHeaderCellDef> Seller </th>
                        <td mat-cell *matCellDef="let ingestionRequest">
                          <mat-chip-list>
                            <seller-chip [seller]="ingestionRequest.sellerProfile"></seller-chip>
                          </mat-chip-list>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="created">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by created">Created</th>
                        <td mat-cell *matCellDef="let ingestionRequest" style="position: relative;">
                            <a class="nostyle" style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; z-index: 1" [routerLink]="['/marketplace/ingestion-requests/', ingestionRequest.id]"></a>
                            <a class="nostyle" style="position: relative; z-index: 2" [routerLink]="['/marketplace/ingestion-requests/', ingestionRequest.id]">{{ingestionRequest.displayCreated}}</a>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="updated">
                        <th mat-header-cell *matHeaderCellDef> Updated </th>
                        <td mat-cell *matCellDef="let ingestionRequest" style="position: relative;">
                            <a class="nostyle" style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; z-index: 1" [routerLink]="['/marketplace/ingestion-requests/', ingestionRequest.id]"></a>
                            <a class="nostyle" style="position: relative; z-index: 2" [routerLink]="['/marketplace/ingestion-requests/', ingestionRequest.id]">{{ingestionRequest.displayUpdated}}</a>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell class="p-0" *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let ingestionRequest" style="position: relative;">
                            <a class="nostyle" style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; z-index: 1" [routerLink]="['/marketplace/ingestion-requests/', ingestionRequest.id]"></a>
                            <span style="position: relative; z-index: 2">
                                <button mat-button class="p-0" [matMenuTriggerFor]="menu" (click)="handleMenuClick($event)">
                                    <mat-icon>more_vert</mat-icon>
                                  </button>
                                  <mat-menu #menu="matMenu">
                                    <button *ngIf="!ingestionRequest.flagged" (click)="flag(ingestionRequest)" mat-menu-item>Flag</button>
                                    <button *ngIf="ingestionRequest.flagged" (click)="unflag(ingestionRequest)" mat-menu-item>Unflag</button>
                                    <button *ngIf="ingestionRequest.canArchive" (click)="archive(ingestionRequest)" mat-menu-item>Archive</button>
                                    <button (click)="openInNewTab(ingestionRequest)" mat-menu-item>Open URL in new tab</button>
                                  </mat-menu>
                            </span>
                        </td>
                      </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let ingestionRequest; columns: displayedColumns;">
                </table>
            </div>
        </div>
        <mat-paginator [length]="length" [pageIndex]="page" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
            (page)="pageChangeEvent($event)" aria-label="Select page">
        </mat-paginator>
    </mat-card>
    <button mat-fab [routerLink]="['/marketplace/ingestion-requests/new']" class="fab">
        <mat-icon>add</mat-icon>
    </button>
</div>