import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { UsersService } from 'src/app/_services/users.service';

@Component({
  selector: 'app-confirm-delete-user',
  templateUrl: './confirm-delete-user.component.html',
  styleUrls: ['./confirm-delete-user.component.css']
})
export class ConfirmDeleteUserComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<ConfirmDeleteUserComponent>,
    @Inject(MAT_DIALOG_DATA) private data: {userId: string},
    private usersService: UsersService,
    private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
  }

  onDelete(): void {
    this.usersService.deleteUser(this.data.userId).subscribe(({userId}) => {
      this.closeDialog({
        deletedUserId: userId
      });
    });
  }

  onCancel(): void {
    this.closeDialog({});
  }

  closeDialog(result: {
    deletedUserId?: string
  }) {
    this.dialogRef.close(result);
  }
}
