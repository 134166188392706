<div class="container" *ngIf="!loading && !submitting; else spinner">
  <mat-card class="m-2">
    <mat-card-title>{{pageTitle}}</mat-card-title>
    <mat-card-content>
      <h2>General Information</h2>
      <div class="m-2 d-flex flex-column" style="gap: 10px">
        <div class="flex-fill d-flex" style="gap: 10px">
          <div class="flex-fill" style="position: relative;">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Model</mat-label>
              <input matInput type="search" placeholder="Add models..." class="w-100 h-100"
                (input)='onSearchTextChanged($event.target.value)' (blur)="onBlur()" (focus)="onFocus()"  (keydown.enter)="onEnterPressed()"
                [(ngModel)]="modelText" required="true">
              <button *ngIf="modelText" matSuffix mat-icon-button aria-label="Clear" (click)="clearModelSelection()">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <div class="search-results-container" style="position: absolute; top: 100%;"
            *ngIf="(searchResults | async)!.length > 0 && !hideSearchResults">
              <div class="search-result" *ngFor="let model of (searchResults | async)"
                (click)="onSearchResultClicked(model)" (mouseenter)="mouseEnterSearchResults(model)"
                (mouseleave)="mouseExitSearchResults()">
                <div class="image" *ngIf="model.images?.[0]?.url">
                  <img [src]="model.images?.[0]?.url">
                </div>
                <div class="title">
                  {{model.brand.displayName ?? model.brand.name}} {{model.displayName ?? model.name}}
                  {{model.referenceNumber}}
                </div>
              </div>
              <div *ngIf="hoveredModel" class="search-results-container"
                style="position: absolute; left: calc(100% + 18px); top: 0; width: 400px">
                <img [src]="hoveredModel.images?.[0]?.url" style="object-fit: contain; width: 100%;">
              </div>
            </div>
          </div>
          <seller-select [value]="selectedSeller.value" (onSelect)="onSelectSeller($event)" class="flex-fill" required
            [disabled]="ingestionRequest"></seller-select>
        </div>
        <mat-form-field appearance="outline" class="flex-fill">
          <mat-label>Accessories</mat-label>
          <mat-select [formControl]="selectedAccessories" multiple>
            <mat-option *ngFor="let accessory of accessories" [value]="accessory.id">{{accessory.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="flex-fill d-flex" style="gap: 10px">
          <mat-form-field appearance="outline" class="flex-fill">
            <mat-label>Manufacture Month</mat-label>
            <input matInput type="number" placeholder="11" [formControl]="manufactureMonth">
          </mat-form-field>
          <mat-form-field appearance="outline" class="flex-fill">
            <mat-label>Manufacture Year</mat-label>
            <input matInput type="number" placeholder="2020" [formControl]="manufactureYear">
          </mat-form-field>
        </div>
        <div class="flex-fill d-flex" style="gap: 10px">
          <mat-form-field appearance="outline" class="flex-fill">
            <mat-label>Purchase Month</mat-label>
            <input matInput type="number" placeholder="11" [formControl]="purchaseMonth">
          </mat-form-field>
          <mat-form-field appearance="outline" class="flex-fill">
            <mat-label>Purchase Year</mat-label>
            <input matInput type="number" placeholder="2020" [formControl]="purchaseYear">
          </mat-form-field>
          <mat-checkbox class="flex-fill" [checked]="originalOwner" (change)="setOriginalOwner($event.checked)">
            Seller is Original Owner
          </mat-checkbox>
        </div>
      </div>

      <h2 class="mt-4">Condition</h2>
      <div class="m-2 d-flex flex-column" style="gap: 10px">
        <div>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Condition</mat-label>
            <mat-select [formControl]="condition" required>
              <mat-option *ngFor="let c of conditionOptions" [value]="c">
                {{displayCondition(c)}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <h3>Issues</h3>
          <button mat-button (click)="openIssueDialog()">
            <mat-icon class="m-1">add</mat-icon>New Issue
          </button>
          <div class="d-flex flex-row flex-wrap">
            <div *ngFor="let issue of issueResponses">
              <div class="issue-card border rounded p-2 m-2 d-flex flex-column justify-content-between">
                <h5>{{getIssueById(issue.issue.id).category}} - {{getIssueById(issue.issue.id).name}}</h5>
                <span class="mb-4">{{issue.answer}}</span>
                <button mat-raised-button color="warn" (click)="removeIssueResponse(issue)">Remove</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h2 class="mt-4">Images</h2>
      <div style="gap: 20px">
        <listing-image-grid [existingImages]="existingImages" (statusChange)="onImageStatusChange($event)"></listing-image-grid>
      </div>

      <h2 class="mt-4">Pricing</h2>
      <mat-chip-listbox [formControl]="pricingModel" style="margin-bottom: 24px;">
        <mat-chip-option [value]="'FIXED_PRICE'">Fixed Price</mat-chip-option>
        <mat-chip-option [value]="'AUCTION'">Auction</mat-chip-option>
      </mat-chip-listbox>
      <ng-container *ngIf="pricingModel.value == 'FIXED_PRICE'">
        <div class="m-2 d-flex flex-column" style="gap: 10px">
          <div>
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Price</mat-label>
              <input matInput type="text" placeholder="1999.99" [formControl]="price" required="true">
              <mat-hint align="end"
                *ngIf="ingestionRequest && selectedSeller.value.markupExternalListings && price.value">Commission adjusted
                price: {{(calculateListedPriceCents() / 100).toLocaleString("en-US", {style:"currency",
                currency:"USD"})}}</mat-hint>
            </mat-form-field>
          </div>
          <div>
            <h3>Offers</h3>
            <div class="flex-fill d-flex flex-column" style="gap: 10px">
              <mat-checkbox [formControl]="offersAllowed" [disabled]="!selectedSeller.value.offersAllowed">
                Offers Are Allowed
              </mat-checkbox>
              <mat-form-field appearance="outline" *ngIf="offersAllowed.value" class="w-100">
                <mat-label>Auto Decline Offers Under</mat-label>
                <input matInput type="text" placeholder="1500.50" [formControl]="autoDeclineOffersUnderCents">
                <mat-hint align="end">Reserve price for offers</mat-hint>
              </mat-form-field>
            </div>
          </div>
          <div>
            <h3>Promotion</h3>
            <button mat-button (click)="openPromotionDialog()" class="d-flex align-items-center"
              *ngIf="!promotionPriceCents && !promotionType">
              <mat-icon class="m-1">add</mat-icon>Add Promotion
            </button>
            <div *ngIf="promotionPriceCents || promotionType">
              <div class="issue-card border rounded p-2 m-2 d-flex flex-column justify-content-between">
                <div *ngIf="promotionPriceCents"><b>Price:</b> {{(promotionPriceCents / 100).toLocaleString("en-US",
                  {style:"currency", currency:"USD"})}}</div>
                <div *ngIf="promotionType"><b>Type:</b> {{ promotionType }}</div>
                <div *ngIf="_listing.promotion?.timedDealExpiration == null && promotionDurationSeconds"><b>Duration:</b> {{ promotionDurationSeconds / (60 * 60) }} hours </div>
                <div *ngIf="!!_listing && _listing.promotion?.timedDealExpiration != null"><b>Expiration:</b> {{_listing.displayTimedDealExpiration}}</div>
                <button mat-raised-button color="primary" (click)="openPromotionDialog()" class="mt-4">Edit</button>
                <button mat-raised-button color="warn" (click)="clearPromotion()" class="mt-2">Remove</button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="pricingModel.value == 'AUCTION'">
        <div class="m-2 d-flex flex-column" style="gap: 20px">
          <div class="d-flex flex-row" style="gap: 10px">
            <mat-form-field appearance="outline" class="flex-fill">
              <mat-label>Start Date</mat-label>
              <input matInput [ngxMatDatetimePicker]="startPicker" placeholder="Choose a date" [formControl]="auctionStartDate">
              <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
              <ngx-mat-datetime-picker #startPicker [showSeconds]="false" [enableMeridian]="true">
              </ngx-mat-datetime-picker>
           </mat-form-field>
           <mat-form-field appearance="outline" class="flex-fill">
              <mat-label>End Date</mat-label>
              <input matInput [ngxMatDatetimePicker]="endPicker" placeholder="Choose a date" [formControl]="auctionEndDate">
              <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
              <ngx-mat-datetime-picker #endPicker [showSeconds]="false" [enableMeridian]="true">
              </ngx-mat-datetime-picker>
           </mat-form-field>
          </div>
          <div class="d-flex flex-row" style="gap: 10px">
            <mat-form-field appearance="outline" class="flex-fill">
              <mat-label>Estimate (lower bound)</mat-label>
              <input matInput type="text" placeholder="1999.99" [formControl]="auctionEstimateLowerBound">
            </mat-form-field>
            <mat-form-field appearance="outline" class="flex-fill">
              <mat-label>Estimate (upper bound)</mat-label>
              <input matInput type="text" placeholder="1999.99" [formControl]="auctionEstimateUpperBound">
            </mat-form-field>
          </div>
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Lot Essay</mat-label>
            <textarea matInput placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque luctus eros sit amet diam varius maximus. Nam consequat sem sapien, vitae finibus lectus fringilla non." [formControl]="lotEssay"></textarea>
          </mat-form-field>
          <div class="labeled-box">
            <div class="label">
              Disclaimers
            </div>
            <ng-container *ngFor="let disclaimer of disclaimerOptions | keyvalue">
              <mat-checkbox [(ngModel)]="disclaimer.value" (ngModelChange)="updateDisclaimer(disclaimer.key, disclaimer.value)" [disabled]="disclaimer.key == 'GUARANTEED_BID' && !canEditGuaranteedBidDiclaimer" >
                    {{disclaimer.key}}
              </mat-checkbox>
            </ng-container>
          </div>
          <mat-form-field appearance="outline" class="flex-fill">
            <mat-label>Starting Price (WIP)</mat-label>
            <input matInput type="number" placeholder="11" [formControl]="startingPrice">
          </mat-form-field>
          <div class="labeled-box">
            <div class="label">
              Sale Price Guarantee
            </div>
            <mat-chip-listbox [formControl]="priceGuaranteeType">
              <mat-chip-option [value]="'RESERVE'" [disabled]="!canEnableReserve">Reserve</mat-chip-option>
              <mat-chip-option [value]="'GUARANTEED_BID'" (ngModelChange)="updateDisclaimer('GUARANTEED_BID', true)" >Guaranteed Bid</mat-chip-option>
            </mat-chip-listbox>
            <div class="d-flex" style="gap: 10px; margin-top: 20px;" *ngIf="priceGuaranteeType.value === 'RESERVE'">
                <mat-form-field appearance="outline" class="flex-fill">
                  <mat-label>Price ($)</mat-label>
                  <input matInput type="number" placeholder="10250" [formControl]="reserveDollars">
                </mat-form-field>
            </div>
            <div class="d-flex" style="gap: 10px; margin-top: 20px;" *ngIf="priceGuaranteeType.value === 'GUARANTEED_BID'">
                <buyer-select label="Guarantor" placeholder="Select buyer" [control]="guarantor" class="flex-fill"></buyer-select>
                <mat-form-field appearance="outline">
                  <mat-label>Price ($)</mat-label>
                  <input matInput type="number" placeholder="10250" [formControl]="guaranteedBidDollars">
                </mat-form-field>
            </div>
          </div>
        </div>
      </ng-container>
      <h2 class="mt-4">External Syncing</h2>
      <div class="m-2 d-flex flex-column" style="gap: 10px">
        <div class="flex flex-row gap-8">
          <mat-checkbox class="flex-fill" [formControl]="externalSyncEnabled">
            External Sync Enabled
          </mat-checkbox>
          <mat-checkbox class="flex-fill" [formControl]="externalPriceSyncEnabled">
            External Price Sync Enabled
          </mat-checkbox>
        </div>
        <mat-form-field appearance="outline" class="flex-fill">
          <mat-label>External URL</mat-label>
          <input matInput type="string" placeholder="https://abc.xyz/listings/1234" [formControl]="externalUrl">
        </mat-form-field>
      </div>

      <h2 class="mt-4">Miscellaneous</h2>
      <div class="m-2 d-flex flex-column" style="gap: 10px">
        <mat-checkbox class="flex-fill" [formControl]="autoDuplicateOnSale">
          Automatically Duplicate After Selling
        </mat-checkbox>
        <mat-form-field appearance="outline" class="flex-fill">
          <mat-label>Seller SKU</mat-label>
          <input matInput type="string" placeholder="1234ABCD" [formControl]="inventoryNumber">
        </mat-form-field>
        <mat-form-field appearance="outline" class="flex-fill">
          <mat-label>Seller Notes</mat-label>
          <input matInput type="string" placeholder="" [formControl]="sellerNotes">
        </mat-form-field>
        <mat-form-field appearance="outline" class="flex-fill">
          <mat-label>Desired Net Payout</mat-label>
          <input matInput type="string" placeholder="" [formControl]="desiredNetPayoutCents">
        </mat-form-field>
        <div *ngIf="_listing">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Tags</mat-label>
            <mat-chip-grid #tagsChipGrid class="tags-chip-grid">
              <mat-chip-row *ngFor="let tag of _listing.tags" (removed)="removeTag(tag.id)" [editable]="false">
                {{tag.displayValue}}
                <button matChipRemove [attr.aria-label]="'remove ' + value" *ngIf="tag.stableKey == null">
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-chip-row>
            </mat-chip-grid>
            <input placeholder="Add Tag" #tagInput [formControl]="selectedTag" [matChipInputFor]="tagsChipGrid"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
              (matChipInputTokenEnd)="addTag()" [matAutocomplete]="tagAutoComplete">
            <mat-autocomplete #tagAutoComplete="matAutocomplete" (optionSelected)="addTag()">
              <mat-option *ngFor="let tag of availableTags" [value]="tag.id">
                {{tag.displayValue}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div>
          <button mat-raised-button (click)="openCreateTagDialog()">
            <mat-icon class="m-1">add</mat-icon>Add Tag
          </button>
        </div>
      </div>
    </mat-card-content>
    <mat-card-footer class="m-3 d-flex justify-content-between">
      <div>
        <button mat-raised-button color="warn" (click)="onCancel()">Cancel</button>
      </div>
      <div class="d-flex" style="gap: 10px">
        <button mat-button color="primary" (click)="onSubmit(false, true)" [disabled]="!isSaveableListing()"
          *ngIf="!_listing || _listing.status === 'DRAFT'">Save as draft</button>
        <button mat-raised-button color="primary" (click)="onSubmit(false)" [disabled]="!isCompleteListing()">
          <ng-container *ngIf="!editing || _listing.status === 'DRAFT'">Submit for review</ng-container>
          <ng-container *ngIf="!(!editing || _listing.status === 'DRAFT')">Save</ng-container>
        </button>
        <button mat-raised-button color="primary" (click)="onSubmit(true)" [disabled]="!isCompleteListing()"
          *ngIf="!editing">Submit for review and add another</button>
      </div>
    </mat-card-footer>
  </mat-card>
</div>
<ng-template #spinner>
  <mat-card class="m-2 d-flex flex-column align-items-center">
    <mat-spinner diameter="40" class="loading-spinner"></mat-spinner>
  </mat-card>
</ng-template>
