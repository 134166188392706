import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, Subscription } from 'rxjs';
import { ListingsQuery, ListingsService } from 'src/app/_services/listings.service';
import { ToolbarService, ToolbarController } from 'src/app/_services/toolbar.service';
import Listing from 'src/app/models/listing.model';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import SellerProfile from 'src/app/models/seller-profile.model';
import { environment } from 'src/environments/environment';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import Model from 'src/app/models/model.model';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ModelsService } from 'src/app/_services/models.service';

@Component({
  selector: 'app-browse-auctions',
  templateUrl: './browse-auctions.component.html',
  styleUrls: ['./browse-auctions.component.css']
})
export class BrowseAuctionsComponent implements OnInit, OnDestroy, ToolbarController {
  title = new BehaviorSubject<string>('Auctions');

  listings: Listing[] = [];
  displayedColumns: string[] = ['flagged', 'id', 'status', 'model', 'condition', 'seller', 'startDate', 'endDate', 'bidCount', 'created', 'actions'];
  statuses: object[] = [
    { label: 'All', value: 'ALL' },
    { label: 'Draft', value: 'DRAFT' },
    { label: 'Pending Review', value: 'PENDING_REVIEW' },
    { label: 'Unstarted', value: 'UNSTARTED' },
    { label: 'Live', value: 'LIVE' },
    { label: 'Ended', value: 'ENDED' },
    { label: 'Ordered', value: 'ORDERED' },
    { label: 'Rejected', value: 'REJECTED' },
    { label: 'Suspended', value: 'SUSPENDED' },
    { label: 'Archived', value: 'ARCHIVED' },
  ]

  modelFilterControl = new FormControl("");
  searchResults: BehaviorSubject<Model[]> = new BehaviorSubject<Model[]>([]);
  statusFilter: string = 'ALL';
  metReserveFilter: boolean | null = null;
  modelFilter!: number | null;
  sellerProfileFilter!: number | null;
  inventoryNumberFilter!: BehaviorSubject<string | null>;
  flaggedFilter: boolean | null = null;
  tagsFilterControl = new FormControl<number[]>([]);
  tagsFilter: number[] | null = null;
  auctionDateRangeStart: FormControl<Date | null> = new FormControl(null);
  auctionDateRangeEnd: FormControl<Date | null> = new FormControl(null);


  page: number = 0;
  sortKey: string | null = null;
  length = 0;
  pageSize = 20;
  pageSizeOptions: number[] = [20];
  isLoading = true;
  private loadingSubscription: Subscription | null = null

  private _destroyed = new BehaviorSubject<boolean>(false);

  constructor(
    private toolbarService: ToolbarService,
    private listingsService: ListingsService,
    private modelsService: ModelsService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.inventoryNumberFilter = new BehaviorSubject<string | null>(null);
    this.inventoryNumberFilter.pipe(
      debounceTime(300),
      distinctUntilChanged())
      .subscribe(_ => {
        // TODO: Fix this. Uncommenting this resets the page when the component mounts.
        // this.page = 0;
        this.updateUrlParametersIfNeeded();
      });

    this.activatedRoute.queryParams.subscribe(params => {
      this.statusFilter = params.status ? params.status : 'ALL';
      this.modelFilter = params.model ? params.model : null;
      this.page = params.page ? params.page : 0;
      this.sellerProfileFilter = params.seller ? params.seller : null;
      this.inventoryNumberFilter.next(params.inventoryNumber ? params.inventoryNumber : null);
      this.flaggedFilter = params.flagged ? params.flagged : null;
      this.tagsFilter = params.tags ? params.tags : null;
      if (this.tagsFilter) {
        this.tagsFilterControl.setValue(this.tagsFilter);
      }
      this.metReserveFilter = params.metReserve ? params.metReserve : null;
      if (!!params.auctionDateRangeStart) {
        this.auctionDateRangeStart.setValue(new Date(params.dateRangeStart));
      }
      if (!!params.auctionDateRangeEnd) {
        this.auctionDateRangeEnd.setValue(new Date(params.dateRangeEnd));
      }
      this.sortKey = params.sort ? params.sort : null;

      if (this.modelFilter != null && this.modelFilterControl.value == null) {
        modelsService.getModel(this.modelFilter).subscribe(model => {
          if (model == null) {
            return;
          }

          this.modelFilterControl.setValue(`${model.brand.displayName ?? model.brand.name} ${model.displayName ?? model.name}`);
        })
      }
      this.modelFilterControl.valueChanges.subscribe(value => this.onSearchTextChanged(value || ''));

      this.fetchListings(this.page);
    })
  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
    this._destroyed.next(true);
    this._destroyed.complete();
  }

  get loading(): boolean {
    return this.listings.length === 0;
  }

  pageChangeEvent = (event: PageEvent) => {
    this.page = event.pageIndex
    this.updateUrlParametersIfNeeded();
  }

  onSearchTextChanged(query: string) {
    if (query.length < 2) {
      this.searchResults.next([]);
      return;
    }

    this.modelsService.searchModels(query).subscribe({
      next: models => {
        this.searchResults.next(models);
      },
      error: error => {
        console.log(error)
      }
    })
  }

  fetchListings = (index: number) => {
    var statusFilter = this.statusFilter
    if (statusFilter == "UNSTARTED" || statusFilter == "LIVE" || statusFilter == "ENDED") {
      statusFilter = "PUBLISHED"
    }

    this.isLoading = true;
    var queryParams : ListingsQuery = {
        status: (statusFilter == "ALL") ? undefined : statusFilter,
        inventoryNumbers: this.inventoryNumberFilter?.value?.split(",").map(inventoryNumber => inventoryNumber.trim() + "%"),
        sortKey: this.sortKey == null ? "-created" : this.sortKey,
        start: this.pageSize * index,
        model: this.modelFilter ? this.modelFilter : undefined,
        sellerProfileFilter: this.sellerProfileFilter ? this.sellerProfileFilter : undefined,
        flagged: this.flaggedFilter ? this.flaggedFilter : undefined,
        tags: this.tagsFilter ? this.tagsFilter : undefined,
        activePricingModel: 'AUCTION',
        auctionInfo: {
          live: this.statusFilter == "LIVE" || this.statusFilter == "UNSTARTED" ? this.statusFilter == "LIVE" : undefined,
          ended: this.statusFilter == "ENDED" || this.statusFilter == "UNSTARTED" ? this.statusFilter == "ENDED" : undefined,
          dateRangeStart: this.auctionDateRangeStart.value ? new Date(this.auctionDateRangeStart.value) : undefined,
          dateRangeEnd: this.auctionDateRangeEnd.value ? new Date(this.auctionDateRangeEnd.value) : undefined,
          metReserve: this.metReserveFilter ? this.metReserveFilter : undefined,
        }
    };

    if (this.loadingSubscription != null) {
      this.loadingSubscription.unsubscribe();
    }

    this.loadingSubscription =  this.listingsService.getListings(queryParams).subscribe({
      next: (response: any) => {
        this.listings = response.data;
        this.length = response.totalCount;
        this.isLoading = false;
        this.loadingSubscription = null;
      },
      error: (error: any) => {
        console.log(error);
        this.listings = [];
        this.length = 0;
        this.isLoading = false;
        this.loadingSubscription = null;
      }
    });
  };

  onSelectStatus = (event: any) => {
    this.page = 0;
    this.updateUrlParametersIfNeeded();
  }

  onSelectMetReserve = (event: any) => {
  console.log("MR: " + this.metReserveFilter);
    this.updateUrlParametersIfNeeded();
  }

  onDateChange = (event: any) => {
    this.updateUrlParametersIfNeeded();
  }

  onSelectSellerFilter = (seller: SellerProfile) => {
    this.sellerProfileFilter = seller.id;
    this.page = 0;
    this.updateUrlParametersIfNeeded();
  };

  onSearchResultClicked(event: MatAutocompleteSelectedEvent) {
    var model = event.option.value;
    this.modelFilter = model.id;
    this.modelFilterControl.setValue(`${model.brand.displayName ?? model.brand.name} ${model.displayName ?? model.name}`)
    this.searchResults.next([]);
    this.page = 0;
    this.updateUrlParametersIfNeeded();
  }

  onChangeFlaggedFilter(event: any): void {
    this.updateUrlParametersIfNeeded();
  }

  onChangeTagsFilter = (tagIds: number[]) => {
    this.tagsFilter = tagIds.length > 0 ? tagIds : null;
    this.page = 0;
    this.updateUrlParametersIfNeeded();
  };

  clearModelSelection = () => {
    this.modelFilter = null;
    this.modelFilterControl.reset();
    this.updateUrlParametersIfNeeded();
  }

  handleMenuClick = (event: any) => {
    event.stopPropagation();
  }

  onArchive(listingId: number): void {
    this.listingsService.archiveListing(listingId).subscribe({
      next: () => {
        this.fetchListings(this.page);
      },
      error: (error: any) => {
        console.log(error);
      }
    });
  }

  onFlag(listingId: number, flagged: boolean): void {
    this.listingsService.updateListing(listingId, {flagged: flagged}).subscribe({
      next: () => {
        this.fetchListings(this.page);
      },
      error: (error: any) => {
        console.log(error);
      }
    });
  }

  onAddListingButtonPressed(): void {
    this.router.navigate(['marketplace', 'listings', 'new']);
  }

  onSort(event: Sort): void {
    if (event.active == 'created' && event.direction == 'asc') {
      this.sortKey = 'created';
    } else if (event.active == 'created' && event.direction == 'desc') {
      this.sortKey = '-created';
    } else if (event.active == 'model' && event.direction == 'asc') {
      this.sortKey = 'model.id';
    } else if (event.active == 'model' && event.direction == 'desc') {
      this.sortKey = '-model.id';
    } else if (event.active == 'condition' && event.direction == 'asc') {
      this.sortKey = 'condition';
    } else if (event.active == 'condition' && event.direction == 'desc') {
      this.sortKey = '-condition';
    } else if (event.active == 'seller' && event.direction == 'asc') {
      this.sortKey = 'sellerProfile.id';
    } else if (event.active == 'seller' && event.direction == 'desc') {
      this.sortKey = '-sellerProfile.id';
    } else if (event.active == 'startDate' && event.direction == 'asc') {
      this.sortKey = 'auctionInfo.startDate';
    } else if (event.active == 'startDate' && event.direction == 'desc') {
      this.sortKey = '-auctionInfo.startDate';
    } else if (event.active == 'endDate' && event.direction == 'asc') {
      this.sortKey = 'auctionInfo.endDate';
    } else if (event.active == 'endDate' && event.direction == 'desc') {
      this.sortKey = '-auctionInfo.endDate';
    } else {
      this.sortKey = null;
    }
    this.page = 0;
    this.updateUrlParametersIfNeeded()
  }

  openInNewTab(listing: Listing) {
    window.open(location.origin + '/marketplace/listings/' + listing.id);
  }

  onDuplicate(listingId: number): void {
    this.router.navigate(
      ['marketplace', 'listings', 'new'],
      { queryParams: { duplicateFrom: listingId } }
    );
  }

  onViewInBezel(listingId: number): void {
    window.open(environment.bezelDomain + "/listings/" + listingId, "_blank");
  }

  onApprove(listingId: number): void {
    this.listingsService.approveListing(listingId, '').subscribe({
      next: () => {
        this.fetchListings(this.page);
      },
      error: (error: any) => {
        console.log(error);
      }
    });
  }

  private updateUrlParametersIfNeeded() {
    var queryParams: any = {
      status: this.statusFilter == 'ALL' ? null : this.statusFilter,
      model: this.modelFilter,
      seller: this.sellerProfileFilter,
      inventoryNumber: this.inventoryNumberFilter.value,
      flagged: this.flaggedFilter == null ? null : this.flaggedFilter,
      tags: this.tagsFilter,
      metReserve: this.metReserveFilter,
      dateRangeStart: this.auctionDateRangeStart.value,
      dateRangeEnd: this.auctionDateRangeEnd.value,
      page: this.page == 0 ? null : this.page,
      sort: this.sortKey == null ? null : this.sortKey,
    }

    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: queryParams,
        queryParamsHandling: 'merge'
      });
  }
}
