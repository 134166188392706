<div class="d-flex flex-column h-100">
  <mat-card class="m-2">
    <mat-card-content class="d-flex flex-column">
      <div class="d-flex flex-md-row">
        <mat-form-field appearance="outline" class="flex-fill mx-1">
          <mat-label>Status</mat-label>
          <mat-select [(ngModel)]='statusFilter' name='Status' (selectionChange)="onSelectStatus($event)">
            <mat-option *ngFor="let s of statuses" [value]="s.value">
              {{s.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="mx-1 flex-fill">
          <mat-label>Model</mat-label>
          <input matInput placeholder="Add models..." class="w-100 h-100" [formControl]="modelFilterControl" [matAutocomplete]="modelFilterAutocomplete">
          <button *ngIf="modelFilterControl.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearModelSelection()">
            <mat-icon>close</mat-icon>
          </button>
          <mat-autocomplete #modelFilterAutocomplete="matAutocomplete" (optionSelected)="onSearchResultClicked($event)">
            <mat-option *ngFor="let model of searchResults | async" [value]="model">
              <div class="w-100 h-100 d-flex">
                <img [src]="model.images?.[0]?.url" class="image" *ngIf="model.images?.[0]?.url" style="max-height: 40px;">
                <span class="title">
                  {{model.brand.displayName ?? model.brand.name}} {{model.displayName ?? model.name}} {{model.referenceNumber}}
                </span>
              </div>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <seller-select [valueId]="sellerProfileFilter" (onSelect)="onSelectSellerFilter($event)" class="flex-fill mx-1"></seller-select>
        <mat-form-field appearance="outline" class="mx-1 flex-fill">
          <mat-label>Inventory Number</mat-label>
          <input matInput type="search" placeholder="ABCD, 1234, ..." class="w-100 h-100" [ngModel]="inventoryNumberFilter | async" (ngModelChange)="inventoryNumberFilter.next($event)">
          <button *ngIf="(inventoryNumberFilter | async)" matSuffix mat-icon-button aria-label="Clear" (click)="inventoryNumberFilter.next(null)">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <mat-form-field appearance="outline" class="flex-fill mx-1">
          <mat-label>Flagged</mat-label>
          <mat-select [(ngModel)]='flaggedFilter' name='Flagged' (selectionChange)="onChangeFlaggedFilter($event)">
              <mat-option [value]="">-</mat-option>
              <mat-option [value]="'true'">YES</mat-option>
              <mat-option [value]="'false'">NO</mat-option>
          </mat-select>
        </mat-form-field>
        <tag-select (selectionChange)="onChangeTagsFilter($event)" class="flex-fill mx-1"></tag-select>
      </div>
      <br>
      <div class="d-flex flex-md-row">
        <mat-form-field appearance="outline" class="flex-fill">
          <mat-label>Active Date Range Start</mat-label>
          <input matInput [matDatepicker]="startDatepicker" [formControl]="auctionDateRangeStart" (dateChange)="onDateChange($event)">
          <mat-datepicker-toggle matSuffix [for]="startDatepicker"></mat-datepicker-toggle>
          <mat-datepicker #startDatepicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline" class="flex-fill">
          <mat-label>Active Date Range End</mat-label>
          <input matInput [matDatepicker]="endDatepicker" [formControl]="auctionDateRangeEnd" (dateChange)="onDateChange($event)">
          <mat-datepicker-toggle matSuffix [for]="endDatepicker"></mat-datepicker-toggle>
          <mat-datepicker #endDatepicker [startAt]="auctionDateRangeEnd.value"></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline" class="flex-fill mx-1">
          <mat-label>Met Reserve</mat-label>
          <mat-select [(ngModel)]='metReserveFilter' name='MetReserve' (selectionChange)="onSelectMetReserve($event)">
            <mat-option [value]="">-</mat-option>
            <mat-option [value]="'true'">Met Reserve</mat-option>
            <mat-option [value]="'false'">Did Not Meet Reserve</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="mx-2 mb-2 p-0 flex-fill d-flex flex-column overflow-scroll">
    <div class="flex-fill position-relative overflow-scroll">
      <div *ngIf="isLoading" class="w-100 h-100 position-absolute d-flex align-items-center justify-content-center" style="z-index: 1;">
        <div *ngIf="listings.length > 0" class="w-100 h-100 position-absolute" style="background-color: rgba(0, 0, 0, 0.1);"></div>
        <mat-spinner diameter="40" class="loading-spinner"></mat-spinner>
      </div>
      <div class="h-100 w-100 overflow-scroll">
        <table mat-table matSort (matSortChange)="onSort($event)" [dataSource]="listings" class="w-100">
          <ng-container matColumnDef="flagged">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let listing"><mat-icon *ngIf="listing.flagged" color="warn">flag</mat-icon></td>
          </ng-container>
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> Id </th>
            <td mat-cell *matCellDef="let listing"> {{listing.id}} </td>
          </ng-container>
          <ng-container matColumnDef="model">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by model"> Model </th>
            <td mat-cell *matCellDef="let listing">
              <mat-chip-list>
                <model-chip [model]="listing.model"></model-chip>
              </mat-chip-list>
            </td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let listing">
              <div class="badge-narrow-gray" [ngClass]="{
                'badge-narrow-yellow': listing.status === 'PENDING_REVIEW' || (listing.status === 'PUBLISHED' && !listing.auctionInfo?.live && !listing.auctionInfo?.ended),
                'badge-narrow-green': (listing.status === 'PUBLISHED' && listing.auctionInfo?.live) || listing.status === 'ORDERED',
                'badge-narrow-red': listing.status === 'REJECTED',
                'badge-narrow-orange': listing.status === 'SUSPENDED'
                }">
                <ng-container *ngIf="listing.status === 'PUBLISHED' && !listing.auctionInfo?.live && !listing.auctionInfo?.ended">UNSTARTED</ng-container>
                <ng-container *ngIf="listing.status === 'PUBLISHED' && listing.auctionInfo?.live">LIVE</ng-container>
                <ng-container *ngIf="listing.status === 'PUBLISHED' && listing.auctionInfo?.ended">ENDED</ng-container>
                <ng-container *ngIf="listing.status != 'PUBLISHED'">{{listing.displayStatus | uppercase}}</ng-container>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="condition">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by condition"> Condition </th>
            <td mat-cell *matCellDef="let listing"> {{listing.displayCondition}} </td>
          </ng-container>
          <ng-container matColumnDef="seller">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by seller"> Seller </th>
            <td mat-cell *matCellDef="let listing">
              <mat-chip-list>
                <seller-chip [seller]="listing.sellerProfile"></seller-chip>
              </mat-chip-list>
            </td>
          </ng-container>
          <ng-container matColumnDef="startDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by start date" style="min-width: 150px;"> Auction start </th>
            <td mat-cell *matCellDef="let listing"> {{listing.auctionInfo?.startDate | date:'medium'}} </td>
          </ng-container>
          <ng-container matColumnDef="endDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by end date" style="min-width: 150px;"> Auction end </th>
            <td mat-cell *matCellDef="let listing"> {{listing.auctionInfo?.endDate | date:'medium'}} </td>
          </ng-container>
          <ng-container matColumnDef="bidCount">
            <th mat-header-cell *matHeaderCellDef> Bids </th>
            <td mat-cell *matCellDef="let listing">
              <mat-chip-list>
                <mat-chip>{{listing.auctionInfo?.numberOfBids ?? 0}} bid<ng-container *ngIf="listing.auctionInfo?.numberOfBids != 1">s</ng-container> </mat-chip>
              </mat-chip-list>
            </td>
          </ng-container>
          <ng-container matColumnDef="created">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by created" style="min-width: 150px;"> Created </th>
            <td mat-cell *matCellDef="let listing"> {{listing.displayCreated}} </td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th mat-header-cell class="p-0" *matHeaderCellDef></th>
            <td mat-cell class="p-0" *matCellDef="let listing">
              <button mat-button class="p-0" [matMenuTriggerFor]="menu" (click)="handleMenuClick($event)">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="openInNewTab(listing)" mat-menu-item>Open in new tab</button>
                <button mat-menu-item (click)="onApprove(listing.id)" [disabled]="!listing.canApproveOrReject()">Approve</button>
                <button mat-menu-item (click)="onArchive(listing.id)" [disabled]="!listing.canArchive()">Archive</button>
                <button mat-menu-item (click)="onFlag(listing.id, !listing.flagged)">{{ listing.flagged ? 'Unflag' : 'Flag' }}</button>
                <button mat-menu-item (click)="onDuplicate(listing.id)">Duplicate</button>
                <button mat-menu-item *ngIf="listing.isPublic()" (click)="onViewInBezel(listing.id)">View in Bezel <mat-icon>launch</mat-icon></button>
              </mat-menu>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let listing; columns: displayedColumns;" [routerLink]="['/marketplace/listings/', listing.id]">
        </table>
      </div>
    </div>
    <mat-paginator [length]="length"
                [pageIndex]="page"
                [pageSize]="pageSize"
                [pageSizeOptions]="pageSizeOptions"
                (page)="pageChangeEvent($event)"
                aria-label="Select page">
    </mat-paginator>
  </mat-card>
</div>
