import Model from 'src/app/models/model.model';
import BuyerProfile from './buyer-profile.model';
import Transaction from './transaction.model';

export default class PurchaseRequest {
  id!: number;
  buyerProfile!: BuyerProfile;
  model!: Model;
  requestedPriceCents!: number;
  status!: string;
  unwornRestriction?: string;
  yearRestriction?: number;
  activeOfferCount?: number;
  closedOfferCount?: number;
  hold?: Transaction;
  client?: string;
  expiration!: Date;
  created!: Date;
  updated!: Date;
  get statusEng(): string {
    return PURCHASE_REQUEST_STATUS_ENG[PURCHASE_REQUEST_STATUS.indexOf(this.status.toUpperCase())];
  }
  get priceDollars(): string {
    let ps = "$" + this.requestedPriceCents;
    return ps.substring(0, ps.length-2) + ".00";
  }
}

export const PURCHASE_REQUEST_STATUS = [
  'AWAITING_PAYMENT',
  'LIVE',
  'RESERVED',
  'CLOSED',
  'PURCHASED',
  'EXPIRED'
]

export const PURCHASE_REQUEST_STATUS_ENG = [
  'Awaiting Hold Payment',
  'Live',
  'Reserved',
  'Closed',
  'Purchased',
  'Expired'
]

export const UNWORN_RESTRICTION = [
  'PREFER',
  'REQUIRE',
]
