<div class="page-container">
    <div class="top-bar-container d-flex justify-content-between">
        <span>
            <a [routerLink]="['catalog']" *ngIf="canAccessCatalog">Catalog</a>
            <a [routerLink]="['content']" *ngIf="canAccessContent">Content</a>
            <a [routerLink]="['marketplace']" *ngIf="canAccessMarketplace">Marketplace</a>
            <a [routerLink]="['verification']" *ngIf="canAccessVerification">Verification</a>
            <a [routerLink]="['payments']" *ngIf="canAccessPayments">Payments</a>
        </span>
        <span style="margin-top: -10px; margin-right: -6px; display: flex; flex-direction: row; gap: 12px">
            <button mat-button (click)="onLoupePressed()">Loupe</button>
            <button mat-button (click)="onKibanaPressed()">Kibana</button>
            <button mat-button (click)="onMetabasePressed()">Metabase</button>
            <button mat-stroked-button color="warn" (click)="onLogoutPressed()">Logout</button>
        </span>
    </div>
    <div class="search-container">
        <h1>Cloud Console</h1>
        <mat-form-field appearance="outline" class="search-bar">
            <mat-icon matPrefix>search</mat-icon>
            <input matInput placeholder="Search for models, buyers, sellers, listings, orders, ..." (input)='onSearchTextChanged($event.target.value)' (blur)="onBlur()" (focus)="onFocus()">
        </mat-form-field>
        <div class="search-result-container" *ngIf="!isLoading && (mouseHoveringOverResults || isFocused) && hasSearchResults()" (mouseenter)="mouseEnterSearchResults()" (mouseleave)="mouseExitSearchResults()">
            <div class="search-result-section" *ngFor="let section of searchResults; let i = index">
                <div class="section-header" [style.border-top]="(i === 0) ? '0px' : ''">
                    {{getSectionHeader(section.type)}}
                </div>
                <div class="section-results">
                    <div *ngFor="let result of section.results">
                        <ng-container *ngIf="result.type == 'MODEL'">
                            <search-result 
                                [image]="result.object.images[0] ? result.object.images[0].url : 'https://storage.googleapis.com/public-us-standard-bezel_prod/static/model_placeholder.png'"
                                [title]="result.object.displayName" 
                                [subtitle]="result.object.referenceNumber"
                                [routerLink]="['catalog', 'models', result.object.id]">
                            </search-result>
                        </ng-container>
                        <ng-container *ngIf="result.type == 'BUYER_PROFILE'">
                            <search-result
                                [image]="'assets/person.png'" 
                                [title]="result.object.user.fullName" 
                                [subtitle]="result.object.user.email" 
                                [routerLink]="['marketplace', 'buyers', result.object.id]">
                            </search-result>
                        </ng-container>
                        <ng-container *ngIf="result.type == 'SELLER_PROFILE'">
                            <search-result
                                [image]="'assets/storefront.png'" 
                                [title]="result.object.user.fullName" 
                                [subtitle]="result.object.user.email" 
                                [routerLink]="['marketplace', 'sellers', result.object.id]">
                            </search-result>
                        </ng-container>
                        <ng-container *ngIf="result.type == 'LISTING'">
                            <search-result
                                [image]="result.object.images[0].image.url"
                                [title]="(result.object.manufactureYear ? result.object.manufactureYear + ' ' : '') + result.object.model.displayName" 
                                [subtitle]="result.object.condition.toLowerCase() + ' • ' + result.object.status.toLowerCase() + (result.object.inventoryNumber ? ' • ' + result.object.inventoryNumber : '')" 
                                [routerLink]="['marketplace', 'listings', result.object.id]">
                            </search-result>
                        </ng-container>
                        <ng-container *ngIf="result.type == 'ORDER'">
                            <search-result
                                [image]="result.object.listing.images[0].image.url"
                                [title]="(result.object.listing.manufactureYear ? result.object.listing.manufactureYear + ' ' : '') + result.object.listing.model.displayName" 
                                [subtitle]="result.object.status.toLowerCase()" 
                                [routerLink]="['marketplace', 'orders', result.object.id]">
                            </search-result>
                        </ng-container>
                        <ng-container *ngIf="result.type == 'BILLING_ACCOUNT'">
                            <search-result
                                [image]="'assets/payments.png'" 
                                [title]="result.object.owner.fullName" 
                                [subtitle]="result.object.owner.email" 
                                [routerLink]="['payments', 'billing-accounts', result.object.id]">
                            </search-result>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div class="loading-indicator-container" *ngIf="isLoading && isFocused">
            <mat-spinner [diameter]="30"></mat-spinner>
        </div>
    </div>
    <img src="https://storage.googleapis.com/public-us-standard-bezel_prod/static/logo.png" class="logo" />
</div>
