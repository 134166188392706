import PurchaseRequest from './purchase-request.model';
import Listing from './listing.model';
import Offer from './offer.model';

export default class PurchaseRequestOffer {
    id!: number;
    purchaseRequest!: PurchaseRequest;
    listing!: Listing;
    offerPriceCents!: number;
    get offerPriceDollars(): string {
      let ps = "$" + this.offerPriceCents;
      return ps.substring(0, ps.length-2) + ".00";
    }
    strikethroughPriceCents!: number;
    get strikethroughPriceDollars(): string {
      let ps = "$" + this.strikethroughPriceCents;
      return ps.substring(0, ps.length-2) + ".00";
    }
    status!: string;
    get statusEng(): string {
        return PURCHASE_REQUEST_OFFER_STATUS_ENG[PURCHASE_REQUEST_OFFER_STATUS.indexOf(this.status.toUpperCase())];
      }
    onRejectAction!: string;
    counterOffer?: Offer;
    client?: string;
    expiration!: Date;
    created!: Date;
    updated!: Date;
    get isEditable(): boolean {
      return this.status != "EXPIRED" && this.status != "REJECTED" && this.status != "CLOSED";
    }

  }

  export const PURCHASE_REQUEST_OFFER_STATUS = [
    "PENDING_REVIEW",
    "AWAITING_RESPONSE",
    "ACCEPTED",
    "REJECTED",
    "EXPIRED",
    "CLOSED"
  ]

  export const PURCHASE_REQUEST_OFFER_STATUS_ENG = [
    "Pending Review",
    "Awaiting Buyer Response",
    "Accepted",
    "Rejected",
    "Expired",
    "Closed"
  ]

export const ON_REJECT_ACTION = [
    "BECOME_PUBLIC",
    "ARCHIVE"
]
