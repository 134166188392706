import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { MainComponent } from './main/main.component';

import { AuthGuardService } from './_services/auth-guard.service';
import { BrowseBrandsComponent } from './main/catalog/brands/browse-brands/browse-brands.component';
import { BrandsCreateComponent } from './main/catalog/brands/brands-create/brands-create.component';
import { BrowseModelsComponent } from './main/catalog/models/browse-models/browse-models.component';
import { MaterialsHomeComponent } from './main/catalog/materials/materials-home/materials-home.component';
import { MaterialsCreateComponent } from './main/catalog/materials/materials-create/materials-create.component';
import { BrowseSeriesComponent } from './main/catalog/series/browse-series/browse-series.component';
import { SeriesCreateComponent } from './main/catalog/series/series-create/series-create.component';
import { ModelsCreateComponent } from './main/catalog/models/models-create/models-create.component';
import { ColorsHomeComponent } from './main/catalog/colors/colors-home/colors-home.component';
import { ColorsCreateComponent } from './main/catalog/colors/colors-create/colors-create.component';
import { ComplicationsHomeComponent } from './main/catalog/complications/complications-home/complications-home.component';
import { ComplicationsCreateComponent } from './main/catalog/complications/complications-create/complications-create.component';
import { BrandsDetailComponent } from './main/catalog/brands/brands-detail/brands-detail.component';
import { SeriesDetailComponent } from './main/catalog/series/series-detail/series-detail.component';
import { ModelsDetailComponent } from './main/catalog/models/models-detail/models-detail.component';
import { EditSeriesComponent } from './main/catalog/series/edit-series/edit-series.component';
import { EditBrandComponent } from './main/catalog/brands/edit-brand/edit-brand.component';
import { EditModelComponent } from './main/catalog/models/edit-model/edit-model.component';
import { ListingsHomeComponent } from './main/marketplace/listings/listings-home/listings-home.component';
import { ListingsDetailComponent } from './main/marketplace/listings/listings-detail/listings-detail.component';
import { ListingsCreateComponent } from './main/marketplace/listings/listings-create/listings-create.component';
import { ListingsCreateLegacyComponent } from './main/marketplace/listings/listings-create/listings-create-legacy.component';
import { DropsHomeComponent } from './main/marketplace/drops/drops-home/drops-home.component';
import { DropsDetailComponent } from './main/marketplace/drops/drops-detail/drops-detail.component';
import { FeaturesHomeComponent } from './main/content/features/features-home/features-home.component';
import { FeatureDetailComponent } from './main/content/features/feature-detail/feature-detail.component';
import { CreateFeatureComponent } from './main/content/features/create-feature/create-feature.component';
import { DiscountsHomeComponent } from './main/marketplace/discounts/discounts-home/discounts-home.component';
import { CreateDiscountComponent } from './main/marketplace/discounts/create-discount/create-discount.component';
import { DiscountDetailComponent } from './main/marketplace/discounts/discount-detail/discount-detail.component';
import { EditDiscountComponent } from './main/marketplace/discounts/edit-discount/edit-discount.component';
import { OrdersHomeComponent } from './main/marketplace/orders/orders-home/orders-home.component';
import { OrdersDetailComponent } from './main/marketplace/orders/orders-detail/orders-detail.component';
import { SellersHomeComponent } from './main/marketplace/sellers/sellers-home/sellers-home.component';
import { SellersDetailComponent } from './main/marketplace/sellers/sellers-detail/sellers-detail.component';
import { SearchHomeComponent } from './main/search/search-home/search-home.component';
import { BuyersHomeComponent } from './main/marketplace/buyers/buyers-home/buyers-home.component';
import { BuyersDetailComponent } from './main/marketplace/buyers/buyers-detail/buyers-detail.component';
import { IngestionRequestsHomeComponent } from './main/marketplace/ingestion-requests/ingestion-requests-home/ingestion-requests-home.component';
import { IngestionRequestDetailComponent } from './main/marketplace/ingestion-requests/ingestion-request-detail/ingestion-request-detail.component';
import { IngestionRequestCreateComponent } from './main/marketplace/ingestion-requests/ingestion-request-create/ingestion-request-create.component';
import { OffersHomeComponent } from './main/marketplace/offers/offers-home/offers-home.component';
import { OffersDetailComponent } from './main/marketplace/offers/offers-detail/offers-detail.component';
import { BrowseListsComponent } from './main/content/lists/browse-lists/browse-lists.component';
import { BrowseCollectionsComponent } from './main/content/collections/browse-collections/browse-collections.component';
import { BrowseHomeFeedsComponent } from './main/content/home-feeds/browse-home-feeds/browse-home-feeds.component';
import { HomeFeedDetailComponent } from './main/content/home-feeds/home-feed-detail/home-feed-detail.component';
import { CreateHomeFeedComponent } from './main/content/home-feeds/create-home-feed/create-home-feed.component';
import { ListDetailComponent } from './main/content/lists/list-detail/list-detail.component';
import { CreateListComponent } from './main/content/lists/create-list/create-list.component';
import { CollectionDetailComponent } from './main/content/collections/collection-detail/collection-detail.component';
import { CreateCollectionComponent } from './main/content/collections/create-collection/create-collection.component';
import { CreateArticleComponent } from './main/content/articles/create-article/create-article.component';
import { BrowseArticlesComponent } from './main/content/articles/browse-articles/browse-articles.component';
import { ArticleDetailComponent } from './main/content/articles/article-detail/article-detail.component';
import { BrowseBillingAccountsComponent } from './main/payments/browse-billing-accounts/browse-billing-accounts.component';
import { BillingAccountDetailComponent } from './main/payments/billing-account-detail/billing-account-detail.component';
import { BrowseHighlightsComponent } from './main/content/highlights/browse-highlights/browse-highlights.component';
import { CreateHighlightComponent } from './main/content/highlights/create-highlight/create-highlight.component';
import { HighlightDetailComponent } from './main/content/highlights/highlight-detail/highlight-detail.component';
import { ReturnsHomeComponent } from './main/marketplace/returns/returns-home/returns-home.component';
import { ReturnsDetailComponent } from './main/marketplace/returns/returns-detail/returns-detail.component';
import { CreateReturnComponent } from './main/marketplace/returns/create-return/create-return.component';
import { BrowseMaxBidsComponent } from './main/marketplace/max-bids/browse-max-bids/browse-max-bids.component';
import { MaxBidDetailComponent } from './main/marketplace/max-bids/max-bid-detail/max-bid-detail.component';
import { BrowseAuctionsComponent } from './main/marketplace/auctions/browse-auctions/browse-auctions.component';
import { BrowseVerificationReportsComponent } from './main/verification/verification-reports/browse-verification-reports/browse-verification-reports.component';
import { BrowseDiagnosticReportsComponent } from './main/verification/diagnostic-reports/browse-diagnostic-reports/browse-diagnostic-reports.component';
import { BrowseAuthenticationReportsComponent } from './main/verification/authentication-reports/browse-authentication-reports/browse-authentication-reports.component';
import { BrowseIntakeReportsComponent } from './main/verification/intake-reports/browse-intake-reports/browse-intake-reports.component';
import { IntakeReportDetailComponent } from './main/verification/intake-reports/intake-report-detail/intake-report-detail.component';
import { DiagnosticReportDetailComponent } from './main/verification/diagnostic-reports/diagnostic-report-detail/diagnostic-report-detail.component';
import { AuthenticationReportDetailComponent } from './main/verification/authentication-reports/authentication-report-detail/authentication-report-detail.component';
import { VerificationReportDetailComponent } from './main/verification/verification-reports/verification-report-detail/verification-report-detail.component';
import { EditMaxBidComponent } from './main/marketplace/max-bids/edit-max-bid/edit-max-bid.component';
import { ModelsImageCheckComponent } from './main/catalog/models/models-image-check/models-image-check.component';
import { BrowseCategoriesComponent } from './main/catalog/categories/browse-categories/browse-categories.component';
import { CreateCategoryComponent } from './main/catalog/categories/create-category/create-category.component';
import { PurchaseRequestsHomeComponent } from './main/marketplace/purchase-requests/purchase-requests-home/purchase-requests-home.component';
import { PurchaseRequestsDetailComponent } from './main/marketplace/purchase-requests/purchase-requests-detail/purchase-requests-detail.component';
import { PurchaseRequestsCreateComponent } from './main/marketplace/purchase-requests/purchase-requests-create/purchase-requests-create.component';

const routes: Routes = [
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: '',
    canActivate: [AuthGuardService],
    children: [
        { path: '', component: SearchHomeComponent },
        { path: 'catalog', component: MainComponent, children: [
            { path: 'brands', component: BrowseBrandsComponent },
            { path: 'brands/new', component: BrandsCreateComponent },
            { path: 'brands/:brandId', component: BrandsDetailComponent },
            { path: 'brands/:brandId/edit', component: EditBrandComponent },
            { path: 'series', component: BrowseSeriesComponent },
            { path: 'series/new', component: SeriesCreateComponent },
            { path: 'series/:seriesId', component: SeriesDetailComponent },
            { path: 'series/:seriesId/edit', component: EditSeriesComponent },
            { path: 'models', component: BrowseModelsComponent },
            { path: 'models/new', component: ModelsCreateComponent },
            { path: 'models/:modelId', component: ModelsDetailComponent },
            { path: 'models/:modelId/edit', component: EditModelComponent },
            { path: 'model-image-check', component: ModelsImageCheckComponent },
            { path: 'colors', component: ColorsHomeComponent },
            { path: 'colors/new', component: ColorsCreateComponent },
            { path: 'complications', component: ComplicationsHomeComponent },
            { path: 'complications/new', component: ComplicationsCreateComponent },
            { path: 'materials', component: MaterialsHomeComponent },
            { path: 'materials/new', component: MaterialsCreateComponent },
            { path: 'categories', component: BrowseCategoriesComponent },
            { path: 'categories/new', component: CreateCategoryComponent },
            { path: '**', redirectTo: 'models' }]
        },
        { path: 'content', component: MainComponent, children: [
          { path: 'articles', component: BrowseArticlesComponent },
          { path: 'articles/new', component: CreateArticleComponent },
          { path: 'articles/:articleId', component: ArticleDetailComponent },
          { path: 'articles/:articleId/edit', component: CreateArticleComponent },
          { path: 'lists', component: BrowseListsComponent },
          { path: 'lists/new', component: CreateListComponent },
          { path: 'lists/:listId', component: ListDetailComponent },
          { path: 'collections', component: BrowseCollectionsComponent },
          { path: 'collections/new', component: CreateCollectionComponent },
          { path: 'collections/:collectionId', component: CollectionDetailComponent },
          { path: 'collections/:collectionId/edit', component: CreateCollectionComponent },
          { path: 'features', component: FeaturesHomeComponent },
          { path: 'features/new', component: CreateFeatureComponent },
          { path: 'features/:featureId', component: FeatureDetailComponent },
          { path: 'features/:featureId/edit', component: CreateFeatureComponent },
          { path: 'home-feeds', component: BrowseHomeFeedsComponent },
          { path: 'home-feeds/new', component: CreateHomeFeedComponent },
          { path: 'home-feeds/:homeFeedId', component: HomeFeedDetailComponent },
          { path: 'home-feeds/:homeFeedId/edit', component: CreateHomeFeedComponent },
          { path: 'highlights', component: BrowseHighlightsComponent },
          { path: 'highlights/new', component: CreateHighlightComponent },
          { path: 'highlights/:highlightId', component: HighlightDetailComponent },
          { path: 'highlights/:highlightId/edit', component: CreateHighlightComponent },
          { path: '**', redirectTo: 'lists' }]
        },
        { path: 'editorial', redirectTo: 'content' },
        { path: 'marketplace', component: MainComponent, children: [
          { path: 'buyers', component: BuyersHomeComponent },
          { path: 'buyers/:buyerId', component: BuyersDetailComponent },
          { path: 'sellers', component: SellersHomeComponent },
          { path: 'sellers/:sellerId', component: SellersDetailComponent },
          { path: 'listings', component: ListingsHomeComponent },
          { path: 'listings/new', component: ListingsCreateComponent },
          { path: 'listings/new-legacy', component: ListingsCreateLegacyComponent },
          { path: 'listings/:listingId', component: ListingsDetailComponent },
          { path: 'listings/:listingId/edit', component: ListingsCreateComponent },
          { path: 'listings/:listingId/edit-legacy', component: ListingsCreateLegacyComponent },
          { path: 'drops', component: DropsHomeComponent },
          { path: 'drops/:dropId', component: DropsDetailComponent },
          { path: 'orders', component: OrdersHomeComponent },
          { path: 'orders/:orderId', component: OrdersDetailComponent },
          { path: 'returns', component: ReturnsHomeComponent },
          { path: 'returns/new', component: CreateReturnComponent },
          { path: 'returns/:returnId', component: ReturnsDetailComponent },
          { path: 'discounts', component: DiscountsHomeComponent },
          { path: 'discounts/new', component: CreateDiscountComponent },
          { path: 'discounts/:discountId', component: DiscountDetailComponent },
          { path: 'discounts/:discountId/edit', component: EditDiscountComponent },
          { path: 'ingestion-requests', component: IngestionRequestsHomeComponent },
          { path: 'ingestion-requests/new', component: IngestionRequestCreateComponent },
          { path: 'ingestion-requests/:ingestionRequestId', component: IngestionRequestDetailComponent },
          { path: 'ingestion-requests/:ingestionRequestId/edit', component: IngestionRequestCreateComponent },
          { path: 'offers', component: OffersHomeComponent },
          { path: 'offers/:offerId', component: OffersDetailComponent },
          { path: 'max-bids', component: BrowseMaxBidsComponent },
          { path: 'max-bids/:bidId', component: MaxBidDetailComponent },
          { path: 'max-bids/:bidId/edit', component: EditMaxBidComponent },
          { path: 'auctions', component: BrowseAuctionsComponent },
          { path: 'purchase-requests', component: PurchaseRequestsHomeComponent },
          { path: 'purchase-requests/new', component: PurchaseRequestsCreateComponent },
          { path: 'purchase-requests/:purchaseRequestId', component: PurchaseRequestsDetailComponent },
          { path: '**', redirectTo: 'listings' }]
        },
        { path: 'verification', component: MainComponent, children: [
          { path: 'verification-reports', component: BrowseVerificationReportsComponent },
          { path: 'verification-reports/:verificationReportId', component: VerificationReportDetailComponent },
          { path: 'intake-reports', component: BrowseIntakeReportsComponent },
          { path: 'intake-reports/:intakeReportId', component: IntakeReportDetailComponent },
          { path: 'diagnostic-reports', component: BrowseDiagnosticReportsComponent },
          { path: 'diagnostic-reports/:diagnosticReportId', component: DiagnosticReportDetailComponent },
          { path: 'authentication-reports', component: BrowseAuthenticationReportsComponent },
          { path: 'authentication-reports/:authenticationReportId', component: AuthenticationReportDetailComponent },
          { path: '**', redirectTo: 'verification-reports' }]
        },
        { path: 'payments', component: MainComponent, children: [
          { path: 'billing-accounts', component: BrowseBillingAccountsComponent },
          { path: 'billing-accounts/:billingAccountId', component: BillingAccountDetailComponent },
          { path: '**', redirectTo: 'billing-accounts' }]
        },
        { path: '**', redirectTo: '' },
    ]},
   { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
