import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, Subscription } from 'rxjs';
import { TitlePathElement, ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';
import PurchaseRequest, { PURCHASE_REQUEST_STATUS, PURCHASE_REQUEST_STATUS_ENG, UNWORN_RESTRICTION } from 'src/app/models/purchase-request.model';
import Model from 'src/app/models/model.model';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { PurchaseRequestService } from 'src/app/_services/purchase-requests.service';
import { ModelsService } from 'src/app/_services/models.service';


@Component({
  selector: 'app-purchase-requests-home',
  templateUrl: './purchase-requests-home.component.html',
  styleUrls: ['./purchase-requests-home.component.css']
})
export class PurchaseRequestsHomeComponent implements OnInit, OnDestroy, ToolbarController, AfterViewInit {
  titlePath!: BehaviorSubject<TitlePathElement[]>;
  title!: BehaviorSubject<string>;

  filterModelIds: number[] = [];
  filterModels: Model[] | null = null;
  statusFilter: String | null = null;
  highlightsMode: boolean = false;

  purchaseRequests : PurchaseRequest[] = [];
  displayedColumns: string[] = ['id', 'status', 'offers', 'model', 'price', 'buyerProfile', 'expiration', 'created'];
  dataSource: MatTableDataSource<PurchaseRequest>;
  modelFilterControl = new FormControl("");
  highlightsModeControl = new FormControl<boolean>(false);
  modelSearchResults: BehaviorSubject<Model[]> = new BehaviorSubject<Model[]>([]);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  page: number = 0;
  sortKey: string | null = null;
  length = 0;
  pageSize = 20;
  pageSizeOptions: number[] = [20, 40, 60, 80, 100];
  isLoadingPRs = true;
  isLoadingModels = false;

  private loadingSubscription: Subscription | null = null

  private _destroyed = new BehaviorSubject<boolean>(false);

  constructor(
    private toolbarService: ToolbarService,
    private purchaseRequestsService: PurchaseRequestService,
    private modelsService: ModelsService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.page = params.page ? params.page : 0;
      this.sortKey = params.sort ? params.sort : null;
      this.filterModels = null;
      if (params.model) {
        let models : number | number[] = params.model;
        if (Array.isArray(models)) {
          this.filterModelIds = models;
        } else {
          this.filterModelIds = [models]
        }
        this.filterModelIds = this.filterModelIds.map(model => typeof model === "string" ? Number.parseInt(model) : model);
      } else {
        this.filterModelIds = [];
      }
      this.statusFilter = params.status ? params.status : null;

      if (params.mode != null && params.mode != undefined) {
        if (params.mode == "highlights" ) {
          this.highlightsModeControl.setValue(true);
          this.highlightsMode = true;
        } else {
          this.highlightsModeControl.setValue(false);
          this.highlightsMode = false;
        }
      } else {
        console.log("No Params");
        this.highlightsModeControl.setValue(false);
        this.highlightsMode = false;
      }

      this.modelFilterControl.valueChanges.subscribe(value => this.onModelSearch(value || ''));
      if (this.filterModelIds.length > 0) {
        this.fetchModels(this.filterModelIds);
      }
      this.fetchPRs(this.page);
    })
    this.dataSource = new MatTableDataSource();

    this.titlePath = new BehaviorSubject<TitlePathElement[]>([{ title: "Purchase Requests", path: ['/marketplace', 'purchase-requests'] }]);
    this.title = new BehaviorSubject<string>("Purchase Request");
  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
    this._destroyed.next(true);
    this._destroyed.complete();
  }

  get loading(): boolean {
    return this.purchaseRequests.length === 0;
  }

  pageChangeEvent = (event: PageEvent) => {
    this.page = event.pageIndex
    // this.updateUrlParametersIfNeeded();
  }

  onSelectStatus = (event: any) => {
    this.updateUrlParametersIfNeeded();
  }

  fetchModels = (modelIds: number[]) => {
    this.isLoadingModels = true;

    this.modelsService.getModels(modelIds).subscribe({
      next: (response: any) => {
        this.filterModels = response;
        this.isLoadingModels = false;
      }
    });
  }

  fetchPRs = (index: number) => {
    this.isLoadingPRs = true;

    if (this.loadingSubscription != null) {
      this.loadingSubscription.unsubscribe();
    }

    let sortKey = this.sortKey == null ? "-id" : this.sortKey;

    if (this.highlightsMode) {
      this.loadingSubscription = this.purchaseRequestsService.getPurchaseRequestHighlights(sortKey).subscribe({
        next: (response: any) => {
          this.purchaseRequests = response.data;
          this.dataSource.data = this.purchaseRequests;
          this.length = this.purchaseRequests.length;
          this.isLoadingPRs = false;
          this.loadingSubscription = null;
        },
        error: (error: any) => {
          console.log(error);
          this.purchaseRequests = [];
          this.length = 0;
          this.isLoadingPRs = false;
          this.loadingSubscription = null;
        }
      });
    } else {
      this.loadingSubscription = this.purchaseRequestsService.getPurchaseRequests(null, null, this.filterModelIds, this.statusFilter, sortKey).subscribe({
        next: (response: any) => {
          this.purchaseRequests = response.data;
          this.dataSource.data = this.purchaseRequests;
          this.length = this.purchaseRequests.length;
          this.isLoadingPRs = false;
          this.loadingSubscription = null;
        },
        error: (error: any) => {
          console.log(error);
          this.purchaseRequests = [];
          this.length = 0;
          this.isLoadingPRs = false;
          this.loadingSubscription = null;
        }
      });
    }
  }

  onSort(event: Sort) : void {
    if (event.active && event.direction) {
      this.sortKey = (event.direction == "asc" ? "" : "-") + event.active;
    } else {
      this.sortKey = null;
    }
    this.updateUrlParametersIfNeeded();
  }

  getPurchaseRequestOffers(pr: PurchaseRequest) : string {

    if (!!pr.activeOfferCount || !!pr.closedOfferCount) {
      let active = pr?.activeOfferCount ?? 0;
      let closed = pr?.closedOfferCount ?? 0;
      return active + " / " + (active + closed);
    } else {
      return "";
    }
  }

  getPurchaseRequestStatuses() : string[] {
    return PURCHASE_REQUEST_STATUS_ENG;
  }

  getStatusIdFor(status: string) {
    let idx = PURCHASE_REQUEST_STATUS_ENG.findIndex((s) => s === status);
    return PURCHASE_REQUEST_STATUS.at(idx);
  }

  getBadge(purchaseRequest: PurchaseRequest) {
    switch(purchaseRequest.status) {
      case 'AWAITING_PAYMENT':
      case 'RESERVED':
        return 'badge-narrow';
        break;
      case 'LIVE':
      case 'PURCHASED':
        return 'badge-narrow-success';
      case 'CLOSED':
      case 'EXPIRED':
        return 'badge-narrow-orange';
      default:
        return 'badge-narrow';
    }
  }

  onModelSearch(query: string) {
    if (query.length < 2) {
      this.modelSearchResults.next([]);
      return;
    }

    this.modelsService.searchModels(query).subscribe({
      next: models => {
        this.modelSearchResults.next(models);
      },
      error: error => {
        console.log(error)
      }
    })
  }

  onModelClicked(event: MatAutocompleteSelectedEvent) {
    var model = event.option.value;
    this.filterModelIds?.push(model.id);
    this.modelFilterControl.setValue("");
    this.updateUrlParametersIfNeeded();
  }

  addButtonPressed() {
    this.router.navigate(["new"], {relativeTo: this.activatedRoute})
  }

  onHighlightsToggle() {
    this.updateUrlParametersIfNeeded();
  }

  private updateUrlParametersIfNeeded() {
    var queryParams: any = {
      page: this.page == 0 ? null : this.page,
      sort: this.sortKey == null ? null : this.sortKey,
      model: this.filterModelIds.length == 0 ? null : this.filterModelIds,
      status: this.statusFilter,
      mode: this.highlightsModeControl.value ? "highlights" : "standard",
    }

    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: queryParams,
        queryParamsHandling: 'merge'
      });
  }
}
