import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SlotData, ItemData } from './image-grid-slot.component';
import { ImageGridCropper } from './image-grid-cropper.component';
import { base64ToFile } from 'ngx-image-cropper';
import { ImageService } from 'src/app/_services/image.service';
import { ImageMetadata } from 'src/app/models/image.model';

@Component({
  selector: 'listing-image-grid',
  templateUrl: './listing-image-grid.component.html',
  styleUrls: ['./listing-image-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageGridComponent implements OnInit {
  @Input() existingImages!: Map<string, ImageMetadata>;

  imageData: Map<string, SlotData> = new Map([
    ["FRONT", {enum: "FRONT", displayName: "Front", bgImage: "https://getbezel.mo.cloudinary.net/static/listing-placeholder-front.png?tx=f_webp,c_limit,w_256,q_auto", callback: this.callback.bind(this)}],
    ["CROWN_SIDE", {enum: "CROWN_SIDE", displayName: "Crown", bgImage: "https://getbezel.mo.cloudinary.net/static/listing-placeholder-crown-side.png?tx=f_webp,c_limit,w_256,q_auto", callback: this.callback.bind(this)}],
    ["OPPOSITE_CROWN_SIDE", {enum: "OPPOSITE_CROWN_SIDE", displayName: "Opposite Crown", bgImage: "https://getbezel.mo.cloudinary.net/static/listing-placeholder-opposite-crown-side.png?tx=f_webp,c_limit,w_256,q_auto", callback: this.callback.bind(this)}],
    ["BACK", {enum: "BACK", displayName: "Back", bgImage: "https://getbezel.mo.cloudinary.net/static/listing-placeholder-back.png?tx=f_webp,c_limit,w_256,q_auto", callback: this.callback.bind(this)}],
    ["BRACELET_TOP", {enum: "BRACELET_TOP", displayName: "Bracelet Top", bgImage: "https://getbezel.mo.cloudinary.net/static/listing-placeholder-bracelet-top.png?tx=f_webp,c_limit,w_256,q_auto", callback: this.callback.bind(this)}],
    ["BRACELET_BOTTOM", {enum: "BRACELET_BOTTOM", displayName: "Bracelet Bottom", bgImage: "https://getbezel.mo.cloudinary.net/static/listing-placeholder-bracelet-bottom.png?tx=f_webp,c_limit,w_256,q_auto", callback: this.callback.bind(this)}],
    ["CLASP", {enum: "CLASP", displayName: "Clasp", bgImage: "https://getbezel.mo.cloudinary.net/static/listing-placeholder-clasp.png?tx=f_webp,c_limit,w_256,q_auto", callback: this.callback.bind(this)}],
    ["ACCESSORY", {enum: "ACCESSORY", displayName: "Accessory", bgImage: "https://getbezel.mo.cloudinary.net/static/listing-placeholder-accessory.png?tx=f_webp,c_limit,w_256,q_auto", callback: this.callback.bind(this)}],
  ]);

  imageAssignments: Map<string, number> = new Map();

  images: Map<number, ItemData> = new Map();

  nextImageId : number = 1;

  constructor(
    public dialog: MatDialog,
    private imageService: ImageService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (!!this.existingImages) {
      for (let slot of this.existingImages.keys()) {
        let md = this.existingImages.get(slot);
        if (!!md) {
          let image = this.createItem(md.rawUrl);
          image.metadata = md;
          this.assignImage(image.internalId, slot);
        }
      }
    }
  }

  fileChangeEvent(event: any): void {
    var files = event.target.files;

    var availableSlots: string[] = [];
    for (let slot of Array.from(this.imageData.keys())) {
      let currentImage = this.imageAssignments.get(slot);
      if (!currentImage) {
        availableSlots.push(slot);
      }
    }

    if (availableSlots.length == 0) {
      return;
    }

    for(let i = 0; i< files.length; i++) {
      let file = files[i];
      if(!file.type.match('image')) {
        continue;
      }

      if (i >= availableSlots.length) {
        return;
      }

      let gridComponent = this;
      let picReader = new FileReader();

      picReader.addEventListener("load", function(event) {
        if (!!event.target && !!event.target.result && typeof event.target.result == "string") {
          let item = gridComponent.createItem(event.target.result);
          let type : string = file.type.replace("image/", "");

          var loadImage = new Image();
          loadImage.onload = function() {
            let currentMetadata = gridComponent.images.get(item.internalId)?.metadata;
            if (!!currentMetadata) {
              currentMetadata.width = loadImage.width;
              currentMetadata.height = loadImage.height;
              gridComponent.setMetadata(item.internalId, currentMetadata);
            }
            if (!type.endsWith("png") && !type.endsWith("jpg")) {
              gridComponent.reformatImage(item.internalId, loadImage, availableSlots[i]);
            }
          };
          loadImage.src = event.target.result;

          gridComponent.assignImage(item.internalId, availableSlots[i]);

          if (type.endsWith("png") || type.endsWith("jpg")) {
            let imageFile = base64ToFile(event.target.result);
            gridComponent.beginUpload(item.internalId, imageFile, type, availableSlots[i]);
          }
      }});
      picReader.readAsDataURL(file);
    }
  }

  public callback(name1: string, name2: string, task: string) {
    let currentImage = this.getItemForSlot(name1);
    if (task == "close") {
      this.assignImage(undefined, name1);
    } else if (task == "crop") {
      if (!currentImage) {
        return;
      }

      this.dialog.open(ImageGridCropper,  {
        width: '50vw',
        data: {
          image: currentImage.image,
          target: name1,
          onClickSave: this.onSaveCroppedImage
        }
      });
    } else if (task == "swap") {
      let imageTwo = this.imageAssignments.get(name2);
      if (!currentImage) {
        this.assignImage(imageTwo, name1);
        this.assignImage(undefined, name2);
      } else {
        this.assignImage(currentImage.internalId, name2);
        this.assignImage(imageTwo, name1);
      }
    }
  }

  private assignImage(item: number | undefined, target: string): void {
    if (item != undefined) {
      this.imageAssignments.set(target, item);
    } else {
      this.imageAssignments.delete(target);
    }
    this.changeDetector.markForCheck();
  }

  private reformatImage(targetImageId: number, img: HTMLImageElement, currentSlot: string): void {
    let canvas = document.createElement('canvas');
    canvas.width = img.width ?? 200;
    canvas.height = img.height ?? 200;
    let ctx = canvas.getContext('2d');

    ctx?.setTransform(1,0,0,1,canvas.width/2, canvas.height/2);
    ctx?.drawImage(img, -canvas.width / 2, -canvas.height /2);

    canvas.toBlob(blob => {
      if (blob == null) {
        this.assignImage(undefined, currentSlot);
        console.log("Unable to convert image format");
        return;
      }
      this.beginUpload(targetImageId, blob, "jpeg", currentSlot)
    }, 'jpeg');
  }

  private beginUpload(targetImageId: number, img: Blob, format: string, currentSlot: string) {
    let currentImage = this.getItemForSlot(currentSlot);
    if (!!currentImage) {
      currentImage.metadata.state = "UPLOADING";
    }
    this.imageService.getUploadUrl(format).subscribe({
      next: response => {
        let metadata = Object.assign(new ImageMetadata(), response);

        if (metadata.filename?.includes('png')) {
          metadata.filetype = "png";
        } else if (metadata.filename?.includes('jpeg')) {
          metadata.filetype = "jpeg";
        } else {
          throw new Error(`Unsupported Image Format: ${metadata.filename}`);
        }
        metadata.filename = metadata.filename.split(".")[0];

        let currentSlot = this.findCurrentSlot(targetImageId);
        if (!!currentSlot) {
          this.uploadImage(targetImageId, img, metadata);
        }
      }
    });
  }

  private uploadImage(targetImageId: number, img: Blob, uploadData: ImageMetadata): void {
    if (uploadData) {
      let uploadResult = this.imageService.uploadImageToUrl(uploadData, img);
      uploadResult.subscribe(ok => {
        uploadData.state = "UPLOADED";
        this.onUploadEnd(targetImageId, uploadData);
      });
    } else {
      console.log("Failed to get upload URL");
      this.onUploadEnd(targetImageId, uploadData);
    }
  }

  private onUploadEnd(imageId: number, uploadData: ImageMetadata) {
    let slot = this.findCurrentSlot(imageId);
    let existingImage = this.images.get(imageId);

    if (!!slot) {
      let uploadedImage = this.createItem(uploadData.rawUrl);
      uploadedImage.metadata = uploadData;
      uploadedImage.metadata.width = existingImage?.metadata.width ?? 0;
      uploadedImage.metadata.height = existingImage?.metadata.height ?? 0;

      this.assignImage(uploadedImage.internalId, slot);
    }
  }

  private findCurrentSlot(imageId: number) : string | null {
    for (let slot of this.imageAssignments.keys()) {
      if (this.imageAssignments.get(slot) == imageId) {
        return slot;
      }
    }
    return null;
  }

  onSaveCroppedImage = (image: string, target: string, format: string, width: number, height: number) => {
    if (!image) {
      return;
    }
    let newImage = this.createItem(image);
    newImage.metadata.width = width;
    newImage.metadata.height = height;

    this.assignImage(newImage.internalId, target);
    let imageBlob = base64ToFile(newImage.image);
    this.beginUpload(newImage.internalId, imageBlob, format, target);
  }

  setMetadata(localId: number, metadata: ImageMetadata) {
    let target = this.images.get(localId);
    if (target != null) {
      target.metadata  = metadata;
    }
  }

  private createItem(image: string) : ItemData {
    let newItemData = new ItemData(image, this.nextImageId);
    this.nextImageId++;
    this.images.set(newItemData.internalId, newItemData);
    return newItemData;
  }

  getItemForSlot(slotId: string) : ItemData | undefined {
    let imageId = this.imageAssignments.get(slotId);
    if (!!imageId) {
      return this.images.get(imageId);
    }
    return undefined;
  }

  // submitting the listing clears the form, so this exports in-use data and deletes obsolete uploads.
  finalize() : Map<string, ImageMetadata> {
    let metadata : Map<string, ImageMetadata> = new Map();
    let usedIds = new Set<number>();
    for (let slot of this.imageAssignments.keys()) {
      let image = this.getItemForSlot(slot);
      if (image != undefined) {
          metadata.set(slot, image.metadata);
          usedIds.add(image.internalId);
      }
    }

    for (let id of this.images.keys()) {
      if (!usedIds.has(id)) {
        let image = this.images.get(id);
        if (!!image && !image.metadata.rawUrl.startsWith("http")) {
          this.imageService.deleteImage(image.metadata.rawUrl);
        }
      }
    }

    return metadata;
  }
}
