<div class="dialog-content">
    <div class="title">Update Buyer</div>
    <div class="break"></div>
    <div *ngIf="!isLoading() else loading">
        <form [formGroup]="formGroup">
            <div class="section-title w100">General</div>
            <div style="margin-bottom: 16px">
                <mat-checkbox formControlName="trusted">Trusted</mat-checkbox>
            </div>
            <div class="section-title w100">Referral Program</div>
            <mat-form-field appearance="outline">
                <mat-label>Referral Code</mat-label>
                <input matInput placeholder="SIMON12" formControlName="referralCode">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Referral Discount ($)</mat-label>
                <input matInput placeholder="250.00" formControlName="referralDiscount">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Referral Reward ($)</mat-label>
                <input matInput placeholder="250.00" formControlName="referralReward">
            </mat-form-field>
        </form>
    </div>
    <ng-template #loading>
        <div class="d-flex flex-column align-items-center m-5">
            <mat-spinner diameter="34" class="loading-spinner"></mat-spinner>
        </div>
    </ng-template>
    <div class="break"></div>
    <div class="d-flex justify-content-between">
        <div class="d-flex">
            <button mat-raised-button color="warn" (click)="deleteButtonPressed();">Delete</button>
        </div>
        <div class="d-flex flex-row-reverse w100">
            <button mat-raised-button color="primary" [disabled]="!formGroup.valid || !formGroup.dirty || isSaving"
                [class.spinner]="isSaving" (click)="saveButtonPressed();">Save</button>
            <button mat-button class="mx-2" [disabled]="isSaving" (click)="cancelButtonPressed();">Cancel</button>
        </div>
    </div>
</div>
