import { capitalize } from 'lodash';
import PayoutAccount from './payout-account.model';
import User from './user.model';
import Address from './address.model';

export default class SellerProfile {
  id!: number;
  user!: User;
  payoutAccount!: PayoutAccount;
  live!: boolean;
  restricted!: boolean;
  type!: string;
  trusted!: boolean;
  sellerLevel!: number;
  overrideCommissionRateBips!: number;
  overrideSellerFeeCents!: number;
  totalSalesValue!:number;
  usesSkuNumbers!:boolean;
  externalListingSitemapUrl!:string;
  markupExternalListings!:boolean;
  externalListingsMarkupBips!:number;
  offersAllowed!: boolean;
  autoDeclineOffersUnderBips!: number;
  shippingAddress!: Address;
  created!: Date;
  updated!: Date;

  get displayName() {
    var labelParts: string[] = [];
    if (this.user.givenName) {
      labelParts.push(this.user.givenName);
    }
    if (this.user.familyName) {
      labelParts.push(this.user.familyName);
    }
    labelParts.push(`(${this.user.email})`);

    return labelParts.join(' ');
  }

  get shortDisplayName() {
    if (this.displayName.length > 40) {
      return this.user.email;
    } else {
      return this.displayName;
    }
  }

  get displayPayoutMethodStatus() {
    return capitalize(this.payoutAccount.verificationStatus);
  }

  get displayVerificationStatus() {
    return capitalize(this.payoutAccount.payoutMethodStatus);
  }
}

export const SELLER_PROFILE_TYPES = [
  "RETAIL",
  "PROFESSIONAL"
]
