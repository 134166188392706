import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import Model from 'src/app/models/model.model';

@Component({
  selector: 'model-chip',
  templateUrl: './model-chip.component.html',
  styleUrls: ['./model-chip.component.css']
})
export class ModelChipComponent implements OnInit, OnDestroy {
  @Input('model') model?: Model;
  @Input('trim') trim?: number;
  @Input('extraShort') extraShort?: boolean;

  constructor() { }

  ngOnInit(): void { }

  ngOnDestroy(): void { }

  getText(): string {
    if(!!this.model) {
      let trimLen = this?.trim ?? 40;
      if (this?.extraShort) {
        trimLen = trimLen - this.model.referenceNumber.length;
      }

      let displayName = this?.model?.displayName?.substring(0,trimLen).trim();

      return displayName +'...' + this.model?.referenceNumber;
    }
    return "";
  }
}
