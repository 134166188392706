<div class="d-flex flex-row" style="padding-top: 52px;" *ngIf="!isLoading() else loading">
  <div class="d-flex flex-column" style="width: 292px; margin-left: 52px;">
    <div class="title">{{ seller.user.fullName }}</div>
    <div class="subtitle">{{ seller.user.email }}</div>
    <div class="d-flex flex-row" style="gap: 6px">
      <div class="badge-narrow" style="margin-top: 12px">
        {{seller.type}}
      </div>
      <div *ngIf="seller.trusted" class="badge-narrow badge-narrow-gold d-flex flex-row align-items-center" style="margin-top: 12px; gap: 3px">
        TRUSTED
      </div>
    </div>
    <div class="section" style="margin-top: 46px">
      <div class="header">Insights</div>
      <div class="break"></div>
      <div class="d-flex flex-row">
        <div class="flex-even d-flex flex-column label" style="margin-top: 0;">
          <div class="name">Since</div>
          <div class="value">{{ seller.user.created | date:'MMM y' }}</div>
        </div>
        <div class="flex-even d-flex flex-column label" style="margin-top: 0;">
          <div class="name">Inventory</div>
          <div class="value">{{ publishedListingStats.totalValue / 100 | currency : 'USD' : 'symbol' : '0.0-0' }}</div>
        </div>
        <div class="flex-even d-flex flex-column label" style="margin-top: 0;">
          <div class="name">Sales</div>
          <div class="value">{{ seller.totalSalesValue | currency : 'USD' : 'symbol' : '0.0-0' }}</div>
        </div>
      </div>
    </div>
    <div class="section" style="margin-top: 46px">
      <div class="header">
        Details
        <button class="edit-button" (click)="editButtonPressed()">Edit</button>
      </div>
      <div class="break"></div>
      <div class="subsection">
        <div class="header">Account</div>
        <div class="d-flex flex-column label">
          <div class="name">Name</div>
          <div class="value">{{ seller.user.fullName }}</div>
        </div>
        <div class="d-flex flex-column label">
          <div class="name">Email Address</div>
          <div class="value">{{ seller.user.email }}</div>
        </div>
        <div class="d-flex flex-column label">
          <div class="name">Phone Number</div>
          <div class="value">{{ seller.user.phoneNumber || '(none)' }}</div>
        </div>
        <div *ngIf="seller.shippingAddress.line1 != null" class="d-flex flex-column label">
          <div class="name">Shipping Address</div>
          <div class="value">{{seller.shippingAddress.fullName}}</div>
          <div class="value">{{seller.shippingAddress.line1}}</div>
          <div *ngIf="seller.shippingAddress.line2 != null" class="value">{{seller.shippingAddress.line2}}</div>
          <div *ngIf="seller.shippingAddress.line3 != null" class="value">{{seller.shippingAddress.line3}}</div>
          <div class="value">{{seller.shippingAddress.city}} {{seller.shippingAddress.state}} {{seller.shippingAddress.postalCode}}</div>
          <div class="value">{{seller.shippingAddress.phoneNumber}}</div>
        </div>
      </div>
      <div class="spacing"></div>
      <div class="subsection">
        <div class="header w100" style="position: relative;">
          Fees
          <div class="badge"
            style="position: absolute; top: 50%; right: 0; -ms-transform: translate(0, -50%); transform: translate(0, -50%);">
            Level {{ sellerLevel.level }}
          </div>
        </div>
        <div class="d-flex flex-column label">
          <div class="name">Commission Rate</div>
          <div class="value">
            <ng-container *ngTemplateOutlet="commissionRate"></ng-container>
          </div>
        </div>
        <div class="d-flex flex-column label">
          <div class="name">Seller fee</div>
          <div class="value">
            <ng-container *ngTemplateOutlet="sellerFee"></ng-container>
          </div>
        </div>
      </div>
      <div class="spacing"></div>
      <div class="subsection">
        <div class="header">Payout</div>
        <div class="d-flex flex-column label">
          <div class="name">Balance</div>
          <div class="value">{{ seller.payoutAccount.availableBalanceCents / 100 | currency:'USD' }}</div>
        </div>
        <div class="w100" style="position: relative;">
          <div class="label">
            <div class="name">Identity verification</div>
          </div>
          <div class="badge-narrow" [ngClass]="{
                'badge-narrow-success': seller.payoutAccount.verificationStatus === 'ACTIVE',
                'badge-narrow-error': seller.payoutAccount.verificationStatus === 'REJECTED' || seller.payoutAccount.verificationStatus === 'UPDATE_NEEDED'
              }"
            style="position: absolute; top: 50%; right: 0; -ms-transform: translate(0, -50%); transform: translate(0, -50%);">
            {{ seller.payoutAccount.verificationStatus }}
          </div>
        </div>
        <div class="w100" style="position: relative;">
          <div class="label">
            <div class="name">Payout method</div>
          </div>
          <div class="badge-narrow" [ngClass]="{
                'badge-narrow-success': seller.payoutAccount.payoutMethodStatus === 'ACTIVE',
                'badge-narrow-error': seller.payoutAccount.payoutMethodStatus === 'NEEDS_UPDATE'
              }"
            style="position: absolute; top: 50%; right: 0; -ms-transform: translate(0, -50%); transform: translate(0, -50%);">
            {{ seller.payoutAccount.payoutMethodStatus }}
          </div>
        </div>
      </div>
      <div class="spacing"></div>
      <div class="subsection">
        <div class="header">Settings</div>
        <div class="d-flex flex-column label">
          <div class="name">SKU Numbers</div>
          <div class="value">{{ seller.usesSkuNumbers ? 'Enabled' : 'Disabled' }}</div>
        </div>
      </div>
      <div class="spacing"></div>
      <div class="subsection">
        <div class="header">External Syncing</div>
        <div class="d-flex flex-column label">
          <div class="name">Listing Sitemap URL</div>
          <div class="value">
            <ng-container *ngIf="seller.externalListingSitemapUrl">
              <a [href]="seller.externalListingSitemapUrl">{{seller.externalListingSitemapUrl}}</a>
            </ng-container>
            <ng-container *ngIf="!seller.externalListingSitemapUrl">
              (none)
            </ng-container>
          </div>
        </div>
        <div class="d-flex flex-column label">
          <div class="name">Markup External Listings</div>
          <div class="value">
            {{ seller.markupExternalListings ? 'Enabled' : 'Disabled' }}
            <ng-container *ngIf="seller.markupExternalListings">
              (<ng-container
                *ngIf="seller.externalListingsMarkupBips then overrideExternalMarkup else defaultExternalMarkup"></ng-container>)
            </ng-container>
            <ng-template #defaultExternalMarkup>
              <ng-container *ngTemplateOutlet="commissionRate"></ng-container> + <ng-container
                *ngTemplateOutlet="sellerFee"></ng-container>
            </ng-template>
            <ng-template #overrideExternalMarkup>
              <span>{{ seller.externalListingsMarkupBips / 10000 | percent:'0.0-2' }}</span>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex-fill d-flex flex-column" style="margin-left: 52px; margin-right: 52px;">
    <div class="section">
      <div class="header">Overview</div>
      <div class="break"></div>
      <div class="subsection">
        <div class="header">Sales</div>
        <table mat-table [dataSource]="recentOrders" *ngIf="recentOrders.length > 0">
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> Id </th>
            <td mat-cell *matCellDef="let order"> {{ order.id }} </td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let order">
              <div class="badge-narrow" [ngClass]="{
                  'badge-narrow-success': order.status === 'DELIVERED',
                  'badge-narrow-error': order.status === 'CANCELLED'
                }">
                {{ order.status }}
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef> Description </th>
            <td mat-cell *matCellDef="let order"> {{ order.listing.displayName }} </td>
          </ng-container>
          <ng-container matColumnDef="price">
            <th mat-header-cell *matHeaderCellDef> Price </th>
            <td mat-cell *matCellDef="let order"> {{ order.invoice.basePriceCents / 100 | currency:'USD' }} </td>
          </ng-container>
          <ng-container matColumnDef="created">
            <th mat-header-cell *matHeaderCellDef> Created </th>
            <td mat-cell *matCellDef="let order"> {{ order.created | date:'MMM d, H:mm aa' }} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="recentOrderTableCols; sticky: true"></tr>
          <tr mat-row *matRowDef="let order; columns: recentOrderTableCols;"
            [routerLink]="['/marketplace/orders/', order.id]">
          </tr>
        </table>
        <button mat-button [routerLink]="['/marketplace/orders']" [queryParams]="{ seller: seller.id }"
          *ngIf="recentOrders.length > 0">View all sales →</button>
        <div style="opacity: 0.5; margin-top: 10px;" *ngIf="recentOrders.length == 0">No sales</div>
      </div>
      <div class="subsection" style="margin-top: 20px">
        <div class="header">Listings</div>
        <table mat-table [dataSource]="recentListings" *ngIf="recentListings.length > 0">
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> Id </th>
            <td mat-cell *matCellDef="let listing"> {{ listing.id }} </td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let listing">
              <div class="badge-narrow" [ngClass]="{
                  'badge-narrow-success': listing.status === 'PUBLISHED' || listing.status === 'ORDERED' || listing.status === 'COMPLETE',
                  'badge-narrow-error': listing.status === 'REJECTED' || listing.status === 'SUSPENDED'
                }">
                {{ listing.status }}
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef> Description </th>
            <td mat-cell *matCellDef="let listing"> {{ listing.displayName }} </td>
          </ng-container>
          <ng-container matColumnDef="price">
            <th mat-header-cell *matHeaderCellDef> Price </th>
            <td mat-cell *matCellDef="let listing"> {{ listing.priceCents / 100 | currency:'USD' }} </td>
          </ng-container>
          <ng-container matColumnDef="created">
            <th mat-header-cell *matHeaderCellDef> Created </th>
            <td mat-cell *matCellDef="let listing"> {{ listing.created | date:'MMM d, H:mm aa' }} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="recentListingTableCols; sticky: true"></tr>
          <tr mat-row *matRowDef="let listing; columns: recentListingTableCols;"
            [routerLink]="['/marketplace/listings/', listing.id]">
          </tr>
        </table>
        <button mat-button [routerLink]="['/marketplace/listings']" [queryParams]="{ seller: seller.id }"
          *ngIf="recentListings.length > 0">View all listings →</button>
        <div style="opacity: 0.5; margin-top: 10px;" *ngIf="recentListings.length == 0">No listings</div>
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="d-flex flex-column align-items-center m-5">
    <mat-spinner diameter="34" class="loading-spinner"></mat-spinner>
  </div>
</ng-template>

<ng-template #commissionRate>
  <ng-container
    *ngIf='seller.overrideCommissionRateBips != null then overrideCommissionRate else defaultCommissionRate'></ng-container>
</ng-template>
<ng-template #defaultCommissionRate>
  <span>{{ sellerLevel.commissionRateBips / 10000 | percent:'0.0-2' }}</span>
</ng-template>
<ng-template #overrideCommissionRate>
  <span style="text-decoration: line-through; opacity: 0.3;">{{ sellerLevel.commissionRateBips / 10000 | percent:'0.0-2'
    }}</span>&nbsp;<span>{{ seller.overrideCommissionRateBips / 10000 | percent:'0.0-2' }}</span>
</ng-template>

<ng-template #sellerFee>
  <ng-container
    *ngIf='seller.overrideSellerFeeCents != null then overrideSellerFee else defaultSellerFee'></ng-container>
</ng-template>
<ng-template #defaultSellerFee>
  <span>{{ sellerLevel.sellerFeeCents / 100 | currency:'USD' }}</span>
</ng-template>
<ng-template #overrideSellerFee>
  <span style="text-decoration: line-through; opacity: 0.3;">{{ sellerLevel.sellerFeeCents / 100 | currency:'USD'
    }}</span>&nbsp;<span>{{ seller.overrideSellerFeeCents / 100 | currency:'USD' }}</span>
</ng-template>
