<ng-container *ngIf="!listing && listingId">
    <mat-chip [routerLink]="['/marketplace/listings', listing?.id]">
        <mat-spinner diameter="18"></mat-spinner>
    </mat-chip>
</ng-container>
<ng-container *ngIf="listing">
    <mat-chip [routerLink]="['/marketplace/listings', listing?.id]">
        <mat-icon matChipTrailingIcon *ngIf="isPrivate()" alt="Private">public_off</mat-icon>
        <img matChipAvatar *ngIf="listing?.model?.images?.[0]?.url" [src]="listing?.model?.images?.[0]?.url" />
        {{displayText}} 
    </mat-chip>
</ng-container>
