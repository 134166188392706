import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { capitalize } from 'lodash';
import { ListingsService } from 'src/app/_services/listings.service';
import { UsersService } from 'src/app/_services/users.service';
import { ToolbarService, ToolbarController, TitlePathElement } from 'src/app/_services/toolbar.service';
import Listing from 'src/app/models/listing.model';
import User from 'src/app/models/user.model';
import IssueTemplate from 'src/app/models/issue-template.model';
import PurchaseRequestOffer from 'src/app/models/purchase-request-offer.model';
import { ImageGalleryDialog } from 'src/app/common/image-gallery/image-gallery.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { TimelineEvent } from 'src/app/common/timeline/timeline.component';
import { pluck } from 'rxjs/operators';
import { OrdersService } from 'src/app/_services/orders.service';
import Order from 'src/app/models/order.model';
import IngestionRequest from 'src/app/models/ingestion-request.model';
import { IngestionRequestsService } from 'src/app/_services/ingestion-requests.service';
import { FormControl } from '@angular/forms';
import { BidsTableComponent } from './bids-table/bids-table.component';
import ListingStatusChangeEvent from 'src/app/models/listing-status-change-event.model';
import { PurchaseRequestService } from 'src/app/_services/purchase-requests.service';

@Component({
  selector: 'app-listings-detail',
  templateUrl: './listings-detail.component.html',
  styleUrls: ['./listings-detail.component.scss']
})
export class ListingsDetailComponent implements OnInit, OnDestroy, ToolbarController  {
  listing!: Listing | null;
  order!: Order | null;
  ingestionRequest!: IngestionRequest | null;
  statusHistory!: TimelineEvent[] | null;
  purchaseRequestOffer!: PurchaseRequestOffer | null;
  user: any
  _listingId: number | null = null;
  rationale: string = '';
  titlePath = new BehaviorSubject<TitlePathElement[]>([{ title: "Listings", path: ['/marketplace', 'listings'] }]);
  title = new BehaviorSubject<string>("?");
  issues: IssueTemplate[] = []

  sortedImageKeys: String[] = ["FRONT", "CROWN_SIDE", "OPPOSITE_CROWN_SIDE", "BACK", "BRACELET_TOP", "BRACELET_BOTTOM", "CLASP", "ACCESSORY", "VERIFICATION"];

  private _destroyed = new BehaviorSubject<boolean>(false);

  isFlagging!: boolean;
  isApproving!: boolean;
  isArchiving!: boolean;
  isUnarchiving!: boolean;
  isRejecting!: boolean;
  isEditingNotes = false
  isSavingNotes = false

  internalNotes = new FormControl();

  constructor(
    public dialog: MatDialog,
    private toolbarService: ToolbarService,
    private route: ActivatedRoute,
    private listingsService: ListingsService,
    private ordersService: OrdersService,
    private ingestionRequestsService: IngestionRequestsService,
    private usersService: UsersService,
    private purchaseRequestService: PurchaseRequestService,
    private router: Router
    ) {
      this.route.params.pipe(
        pluck("listingId")
      ).subscribe({
        next: listingId => {
          if (this._listingId == listingId) {
            return;
          }

          this._listingId = listingId;
          this.title.next(listingId.toString());
          this.reloadListing();
        }
      })
    }

    private reloadListing() {
      this.listing = null;
      this.order = null;
      this.user = null;
      this.issues = [];

      this.listingsService.getListing(this._listingId!).subscribe({
        next: (listing: Listing) => {
          var sortedImages = [];
          for (var key of this.sortedImageKeys) {
            var imgIdx = listing.images.findIndex(i => i.type == key);
            if (imgIdx >= 0) {
              sortedImages.push(listing.images[imgIdx]);
            }
          }
          this.listing = listing;
          this.listing.images = sortedImages;
          this.usersService.getUser(listing.sellerProfile.user.uuid).subscribe({
            next: (user: User) => {
              this.user = user;
            },
            error: (error: any) => {
              console.log(error);
            }
          });

          if (listing.issues.length > 0) {
            this.listingsService.getIssues().subscribe({
              next: (issues: IssueTemplate[]) => {
                this.issues = issues;
              },
              error: (error: any) => {
                console.log(error);
              }
            });
          }

          if (listing.status == 'ORDERED') {
            this.ordersService.getMostRecentOrderForListing(listing.id).subscribe({
              next: (order: Order | null) => {
                console.log(order)
                this.order = order
              }
            })
          }

          this.ingestionRequestsService.getIngestionRequests(0, -1, null, null, listing.id, null, null, null).subscribe({
            next: (ingestionRequests: any) => {
              if (ingestionRequests.data.length > 0) {
                this.ingestionRequest = ingestionRequests.data[0];
              } else {
                this.ingestionRequest = null;
              }
            }
          })

          if (listing.privateListingBuyer != null) {
            this.purchaseRequestService.getPurchaseRequestOffers(null, null, [listing.id], false).subscribe({
              next: (response: any) => {
                if (response.data != null && response.data.length > 0) {
                  this.purchaseRequestOffer = response.data[0];
                }
              },
              error: error => {
                console.log(error);
              }
            });
          }
        },
        error: (error: any) => {
          console.log(error);
        }
      });
      this.listingsService.getListingStatusChangeEvents(this._listingId!).subscribe({
        next: (events: ListingStatusChangeEvent[]) => {
          this.statusHistory = [];
          for (var event of events.reverse()) {
            var userString = ""
            if (event.user) {
              let user = Object.assign(new User(), event.user)
              userString = ` by ${user.displayName}`
            }
    
            var rationaleStringPrefix = ""
            var rationaleStringSuffix = ""
            if (event.rationale) {
              if (event.rationale == event.rationale.toUpperCase()) {
                rationaleStringPrefix = `${event.rationale} ⇢ `;
              } else {
                rationaleStringSuffix = ` with rationale "${event.rationale.slice(0, 160)}${event.rationale.length > 160 ? "..." : ""}"`
              }
            }
    
            this.statusHistory.push({
              text: `${rationaleStringPrefix} Status changed to ${event.newStatus}${userString}${rationaleStringSuffix}`,
              timestamp: event.timestamp,
            })
          }
        },
        error: (error: any) => {
          console.log(error);
        }
      })
    }

    ngOnInit(): void {
      this.toolbarService.setController(this);
    }

    ngOnDestroy(): void {
      this.toolbarService.removeController(this);
      this._destroyed.next(true);
      this._destroyed.complete();
    }

    onFlag(flagged: boolean): void {
      this.isFlagging = true
      this.listingsService.updateListing(this._listingId!, { flagged: flagged }).subscribe({
        next: () => {
          this.isFlagging = false
          this.listing!.flagged = flagged
        },
        error: error => {
          console.log(error);
          this.isFlagging = false
        }
      });
    }

    onApprove(): void {
      this.isApproving = true;
      this.listingsService.approveListing(this._listingId!, this.rationale).subscribe({
        next: () => {
          this.reloadListing()
          this.isApproving = false;
        },
        error: (error: any) => {
          console.log(error);
          this.isApproving = false;
        }
      });
    }

    onReject(): void {
      this.isRejecting = true;
      this.listingsService.rejectListing(this._listingId!, this.rationale).subscribe({
        next: () => {
          this.reloadListing()
          this.isRejecting = false;
        },
        error: (error: any) => {
          console.log(error);
          this.isRejecting = false;
        }
      });
    }

    onArchive(): void {
      this.isArchiving = true;
      this.listingsService.archiveListing(this._listingId!, this.rationale).subscribe({
        next: () => {
          this.isArchiving = false;
          this.reloadListing()
        },
        error: (error: any) => {
          this.isArchiving = false;
          console.log(error);
        }
      });
    }

    onUnarchive(): void {
      this.isUnarchiving = true;
      this.listingsService.unarchiveListing(this._listingId!, this.rationale).subscribe({
        next: () => {
          this.isUnarchiving = false;
          this.reloadListing()
        },
        error: (error: any) => {
          this.isUnarchiving = false;
          console.log(error);
        }
      });
    }

    startEditingNotes() {
      this.isEditingNotes = true
      this.internalNotes.setValue(this.listing?.internalNotes)
    }

    cancelEditingNotes() {
      this.isEditingNotes = false
      this.internalNotes.reset()
    }

    saveNotesEdits() {
      this.isSavingNotes = true
      this.internalNotes.disable()
      this.listingsService.updateListing(this._listingId!, { internalNotes: this.internalNotes.value }).subscribe({
        next: () => {
          this.isSavingNotes = false
          this.isEditingNotes = false
          this.internalNotes.enable()
          this.listing!.internalNotes = this.internalNotes.value
        },
        error: error => {
          console.log(error);
          this.isSavingNotes = false
          this.internalNotes.enable()
        }
      });
    }

    onEdit(): void {
      this.router.navigate(['marketplace', 'listings', this._listingId, 'edit']);
    }

    onDuplicate(): void {
      this.router.navigate(['marketplace', 'listings', 'new'], { queryParams: { duplicateFrom: this.listing!.id } });
    }

    onViewInBezel(): void {
      window.open(environment.bezelDomain + "/listings/" + this._listingId, "_blank");
    }

    getIssueById = (id: number) => {
      return this.issues.find(i => i.id == id);
    };

    presentImageKey = (key: string) => {
      return capitalize(key.replace(/_/g, " ").toLowerCase());
    }

    onClickImage = (idx: number) => {
      this.dialog.open(ImageGalleryDialog,  {
        width: '50vw',
        height: '90vh',
        data: { currentIdx: idx, images: this.listing!.images.map(i => i.image.rawUrl) }
      });
    }

    onClickBids(): void {
      var dialogRef = this.dialog.open(BidsTableComponent, {
        width: '700px',
        maxHeight: '80vh',
        data: { listingId: this._listingId },
      });
    }
  }
