<div class="container" *ngIf="purchaseRequest && !isLoading else loading">
  <mat-card>
    <mat-card-title>
      Purchase Request
    </mat-card-title>
    <mat-card-content>
      <ng-container>
        <div class="labeled-box">
          <div class="label">ID</div>
          {{ purchaseRequest.id }}
        </div>
        <div class="labeled-box">
          <div class="label">Buyer Profile</div>
          <buyer-chip [buyer]="purchaseRequest.buyerProfile"></buyer-chip>
        </div>
        <div class="labeled-box">
          <div class="label">Model</div>
          <model-chip [model]="purchaseRequest.model"></model-chip>
        </div>
        <div class="labeled-box">
          <div class="label">Requested Price</div>
            <div class="container" *ngIf="!isEditingPR">
              {{ purchaseRequest.priceDollars }}
            </div>
            <div class="container" *ngIf="isEditingPR">
              <mat-form-field appearance="outline" class="w-100">
                <input matInput type="text" placeholder="1999" [formControl]="priceFormControl" required="true">
                <span matTextPrefix>$&nbsp;</span>
                <span matTextSuffix>.00</span>
              </mat-form-field>
            </div>
        </div>
        <div class="labeled-box">
          <div class="label">Status</div>
          <div class="badge-narrow" [ngClass]="{
            'badge-narrow': purchaseRequest.status === 'AWAITING_PAYMENT',
            'badge-narrow-success': purchaseRequest.status === 'LIVE',
            'badge-narrow-orange': purchaseRequest.status === 'CLOSED',
            }">
                          {{ purchaseRequest.statusEng }}
          </div>
        </div>
        <div class="labeled-box" *ngIf="purchaseRequest.client">
          <div class="label">Client</div>
          <mat-chip-list>
            <mat-chip>{{ clientNames[purchaseRequest.client] }}</mat-chip>
          </mat-chip-list>
        </div>
        <ng-container *ngIf=hasRestrictions()>
          <div class="labeled-box">
            <div class="label">Unworn Restriction</div>
            {{ !!purchaseRequest.unwornRestriction ? purchaseRequest.unwornRestriction : "None" }}
          </div>
          <div class="labeled-box">
            <div class="label">Year Restriction</div>
            {{ !!purchaseRequest.yearRestriction ? purchaseRequest.yearRestriction + "+": "None" }}
          </div>
          <div class="labeled-box">
            <div class="label">Hold</div>
            {{ !!purchaseRequest.hold ? "Yes " + purchaseRequest.hold.id : "No" }}
          </div>
        </ng-container>
        <div class="labeled-box">
          <div class="label">Expiration</div>
          <div class="container" *ngIf="!isEditingPR">
            {{ purchaseRequest.expiration | date:'medium' }}
          </div>
          <div class="container" *ngIf="isEditingPR">
            <mat-form-field appearance="outline" class="w-100">
              <input matInput [matDatepicker]="expPicker" [formControl]="expirationFormControl">
              <mat-hint>MM/DD/YYYY</mat-hint>
              <mat-datepicker-toggle matIconSuffix [for]="expPicker"></mat-datepicker-toggle>
              <mat-datepicker #expPicker></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="labeled-box">
          <div class="label">Created</div>
          {{ purchaseRequest.created | date:'medium' }}
        </div>

        <div *ngIf="isEditingPR">
          <button mat-fab class="fab" (click)="finishEditPR()">
            <mat-icon>check</mat-icon>
          </button>
          <button mat-fab class="fab" (click)="cancelEdit()">
            <mat-icon>cancel</mat-icon>
          </button>
        </div>
        <div *ngIf="!isEditingPR && !isUpdating" class="buttons">
          <button mat-fab class="fab" (click)="doEditPR()">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-raised-button color="warn" (click)="closePR()">
            Close
          </button>
        </div>
      </ng-container>
    </mat-card-content>
  </mat-card>
  <mat-card class="m-2" *ngIf="!!purchaseRequest.hold">
    <mat-card-title>
      <div class="d-flex justify-content-between align-items-center">
          <span>Credit Card Hold</span>
        <span>
          <button class="leaveButton" *ngIf="purchaseRequest.hold.stripeDashboardLink" (click)="onViewPaymentInStripe()" mat-stroked-button>
            View in Stripe
            <mat-icon iconPositionEnd>exit_to_app</mat-icon>
          </button>
        </span>
      </div>
    </mat-card-title>
    <mat-card-content>
      <div>
        <div class="labeled-box">
          <div class="label">
            Total
          </div>
          <span>{{(purchaseRequest.hold.baseAmountCents/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}}</span>
        </div>
        <div class="labeled-box">
          <div class="label">
            Credits Applied
          </div>
          <span>{{purchaseRequest.hold.creditsApplied}} ({{(purchaseRequest.hold.creditsApplied/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}})</span>
        </div>
        <div class="labeled-box">
          <div class="label">
            Amount Due
          </div>
          <span>{{(purchaseRequest.hold.paymentAmountCents/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}}</span>
        </div>
        <div class="labeled-box">
          <div class="label">
            Status
          </div>
          <span>{{purchaseRequest.hold.status}}</span>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card *ngIf="contextListings != null && contextListings.length > 0">
    <mat-card-title>Available Inventory</mat-card-title>
    <mat-card-content>
      <table mat-table matSort [dataSource]="contextListingsDataSource" class="w-100">
        <ng-container matColumnDef="flag">
          <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
          <td mat-cell *matCellDef="let listing">
            <ng-container *ngIf="listing.flagged">
              <mat-icon alt="Flagged">flag</mat-icon>
            </ng-container>
          </td>
        </ng-container>
        <ng-container matColumnDef="listing">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Listing </th>
          <td mat-cell *matCellDef="let listing">
            <listing-chip [listing]="listing" [capText]="25"></listing-chip>
          </td>
        </ng-container>
        <ng-container matColumnDef="seller">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by seller"> Seller </th>
          <td mat-cell *matCellDef="let listing">
            <mat-chip-list>
              <seller-chip [seller]="listing.sellerProfile"  [short]="true"></seller-chip>
            </mat-chip-list>
          </td>
        </ng-container>
        <ng-container matColumnDef="price">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Offer Price </th>
          <td mat-cell *matCellDef="let listing">
            <div class="container">
              {{listing.priceDollars}}
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="condition">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Condition </th>
          <td mat-cell *matCellDef="let listing">
            <div class="container">
              {{listing.displayCondition}}
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
          <td mat-cell *matCellDef="let listing">
            <div class="container">
              {{listing.displayStatus}}
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="age">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Time On Marketplace </th>
          <td mat-cell *matCellDef="let listing">
            <div class="container">
              {{listing.displayAgeDays}}
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="tags">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Tags </th>
          <td mat-cell *matCellDef="let listing">
            <div class="container">
              {{listing.displayTags}}
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="accessories">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Accessories </th>
          <td mat-cell *matCellDef="let listing">
            <div class="container">
              {{listing.displayAccessories}}
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="contextDisplayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let listing; columns: contextDisplayedColumns;">
      </table>
    </mat-card-content>
  </mat-card>
  <mat-card *ngIf="purchaseRequestOffers.length > 0">
    <mat-card-title>Responses</mat-card-title>
    <mat-card-content>
      <table mat-table matSort [dataSource]="offersDataSource" (matSortChange)="onSort($event)" class="w-100">
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
          <td mat-cell *matCellDef="let purchaseRequestOffer"> {{purchaseRequestOffer.id}} </td>
        </ng-container>
        <ng-container matColumnDef="seller">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Seller </th>
          <td mat-cell *matCellDef="let purchaseRequestOffer">
            <seller-chip [seller]="purchaseRequestOffer.listing.sellerProfile" [short]="true"></seller-chip>
          </td>
        </ng-container>
        <ng-container matColumnDef="listing">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Listing </th>
          <td mat-cell *matCellDef="let purchaseRequestOffer">
            <listing-chip [listing]="purchaseRequestOffer.listing" [capText]="25"></listing-chip>
          </td>
        </ng-container>
        <ng-container matColumnDef="offerPrice">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Offer Price </th>
          <td mat-cell *matCellDef="let purchaseRequestOffer">
            <div class="container" *ngIf="isEditingPRO != purchaseRequestOffer.id">
              <strike>{{purchaseRequestOffer.strikethroughPriceDollars}}</strike>  {{purchaseRequestOffer.offerPriceDollars}}
            </div>
            <div class="container" *ngIf="isEditingPRO == purchaseRequestOffer.id">
              <mat-form-field appearance="outline" class="d-flex">
                <input matInput type="text" placeholder="1999" [formControl]="offerPriceFormControl" required="true">
                <span matTextPrefix>$&nbsp;</span>
                <span matTextSuffix>.00</span>
              </mat-form-field>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="strikethroughPrice">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Strikethrough Price </th>
          <td mat-cell *matCellDef="let purchaseRequestOffer"> {{purchaseRequestOffer.strikethroughPriceDollars}} </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
          <td mat-cell *matCellDef="let purchaseRequestOffer">
            <div *ngIf="!purchaseRequestOffer?.counterOffer" class="badge-narrow" [ngClass]="getBadge(purchaseRequestOffer)">
              {{purchaseRequestOffer.statusEng}}
            </div>
            <div class="badge-narrow-yellow" *ngIf="!!purchaseRequestOffer?.counterOffer && purchaseRequestOffer.status === 'AWAITING_RESPONSE'" [routerLink]="['/marketplace/offers', purchaseRequestOffer.counterOffer.id]">
              Countered
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="onReject">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> On Reject </th>
          <td mat-cell *matCellDef="let purchaseRequestOffer"> {{purchaseRequestOffer.onRejectAction}} </td>
        </ng-container>
        <ng-container matColumnDef="expiration">
          <th mat-header-cell *matHeaderCellDef> Expiration </th>
          <td mat-cell *matCellDef="let purchaseRequestOffer">
            <div class="container" *ngIf="isEditingPRO != purchaseRequestOffer.id">
              {{purchaseRequestOffer.expiration | date:'medium' }}
            </div>
            <div class="container" *ngIf="isEditingPRO == purchaseRequestOffer.id">
              <mat-form-field appearance="outline" class="w-100">
                <input matInput [matDatepicker]="oExpPicker" [formControl]="offerExpirationFormControl">
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="oExpPicker"></mat-datepicker-toggle>
                <mat-datepicker #oExpPicker></mat-datepicker>
              </mat-form-field>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="client">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Client </th>
          <td mat-cell *matCellDef="let purchaseRequestOffer">
            <mat-chip>{{ clientNames[purchaseRequestOffer.client] }}</mat-chip>
          </td>
        </ng-container>
        <ng-container matColumnDef="created">
          <th mat-header-cell *matHeaderCellDef> Created </th>
          <td mat-cell *matCellDef="let purchaseRequestOffer"> {{purchaseRequestOffer.created | date:'medium' }} </td>
        </ng-container>
        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef> Edit </th>
          <td mat-cell *matCellDef="let purchaseRequestOffer">
            <div *ngIf="!isEditingPR && isEditingPRO == -1 && !isUpdating && purchaseRequestOffer.isEditable" class="buttons">
              <button mat-fab class="fab" (click)="doEditPRO(purchaseRequestOffer.id)">
                <mat-icon>edit</mat-icon>
              </button>
              <button mat-raised-button color="warn" (click)="closePRO(purchaseRequestOffer.id)">
                Close
              </button>
            </div>
            <div *ngIf="isEditingPRO == purchaseRequestOffer.id">
              <button mat-fab class="fab" (click)="finishEditPRO()">
                <mat-icon>check</mat-icon>
              </button>
              <button mat-fab class="fab" (click)="cancelEdit()">
                <mat-icon>cancel</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let purchaseRequestOffer; columns: displayedColumns;">
      </table>
    </mat-card-content>
  </mat-card>
  <mat-card *ngIf="canAddOffers()" class="m-2">
    <mat-card-title>Add a Response</mat-card-title>
    <mat-card-content>
        <div class="d-flex justify-content-between">
          <seller-select (onSelect)="onSelectSellerFilter($event)" class="flex-fill mx-1" [valueId]="sellerFilter"></seller-select>
        </div>
        <div class="labeled-box m-2 d-flex flex-row" style="gap: 10px">
          <div class="label">Listing Select</div>
          <div *ngIf="getNewOfferListingsMessage() != null">
            {{getNewOfferListingsMessage()}}
          </div>
          <mat-form-field *ngIf="getNewOfferListingsMessage() == null" appearance="outline" class="w-100">
              <mat-label>Relevant Listings From Seller</mat-label>
              <mat-select [(ngModel)]="newOfferListingFilter" placeholder="Listings">
                <mat-option *ngFor="let listing of availableListings" [value]="listing.id">
                  {{getListingString(listing)}}
                </mat-option>
              </mat-select>
          </mat-form-field>
          <div *ngIf="newOfferListingFilter != null">
            <button mat-fab class="fab" (click)="listingDetails()">
              Listing Details
            </button>
          </div>
        </div>
        <div class="m-2 d-flex flex-column" style="gap: 10px">
            <mat-form-field appearance="outline" class="w-100">
            <mat-label>Offered Price</mat-label>
            <input matInput type="text" placeholder="5000" [formControl]="newOfferPriceFormControl" required="true">
            <span matTextPrefix>$&nbsp;</span>
            <span matTextSuffix>.00</span>
            </mat-form-field>
        </div>
        <div class="warning" *ngIf="errorMessage != null">
          {{errorMessage}}
        </div>
    </mat-card-content>
    <mat-card-footer class="m-3 d-flex justify-content-between">
    <div class="d-flex" style="gap: 10px">
        <button mat-raised-button color="primary" (click)="finishCreatePRO()"
        *ngIf="!editing">Create</button>
    </div>
    </mat-card-footer>
</mat-card>

</div>
<ng-template #loading>
  <div class="d-flex flex-column align-items-center m-5">
    <mat-spinner diameter="40" class="loading-spinner"></mat-spinner>
  </div>
</ng-template>
