import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import SellerProfile, { SELLER_PROFILE_TYPES }  from 'src/app/models/seller-profile.model';
import { SellersService } from 'src/app/_services/sellers.service';
import { ConfirmDeleteUserComponent } from 'src/app/common/confirm-delete-user/confirm-delete-user.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-edit-seller-details',
  templateUrl: './edit-seller-details.component.html',
  styleUrls: ['./edit-seller-details.component.css']
})
export class EditSellerDetailsComponent implements OnInit {
  seller!: SellerProfile;

  allTypes: string[];

  formGroup = new FormGroup({
    type: new FormControl(),
    trusted: new FormControl(),
    offersAllowed: new FormControl(true, Validators.required),
    autoDeclineOffersUnderPercent: new FormControl('', Validators.pattern("^[0-9]?[0-9]?([.][0-9]?[0-9]?)?$")),
    overrideCommissionRatePercent: new FormControl('', Validators.pattern("^[0-9]?[0-9]?([.][0-9]?[0-9]?)?$")),
    overrideSellerFeeDollars: new FormControl('', Validators.pattern("^[0-9]?[0-9]?[0-9]?([.][0-9]?[0-9]?)?$")),
    externalListingSitemapUrl: new FormControl('', Validators.pattern("^https?:\/\/.*$")),
    markupExternalListings: new FormControl(false, Validators.required),
    externalListingsMarkupPercent: new FormControl(),
  });
  get formControls() {
    return this.formGroup.controls;
  }

  isSaving: boolean = false

  constructor(
    private router: Router,
    private dialogRef: MatDialogRef<EditSellerDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) private data: {sellerId: number},
    private sellersService: SellersService,
    private dialog: MatDialog
  ) { 
    this.allTypes = SELLER_PROFILE_TYPES;

    this.sellersService.getSellerProfile(this.data.sellerId).subscribe(sellerProfile => {
      this.seller = sellerProfile;

      this.formGroup.controls.type.setValue(this.seller.type);
      this.formGroup.controls.trusted.setValue(this.seller.trusted);
      if (this.seller.overrideCommissionRateBips != null) {
        this.formGroup.controls.overrideCommissionRatePercent.setValue((this.seller.overrideCommissionRateBips / 100).toString());
      }
      if (this.seller.overrideSellerFeeCents != null) {
        this.formGroup.controls.overrideSellerFeeDollars.setValue((this.seller.overrideSellerFeeCents / 100).toString());
      }
      if (this.seller.externalListingSitemapUrl != null) {
        this.formGroup.controls.externalListingSitemapUrl.setValue(this.seller.externalListingSitemapUrl);
      }
      if (this.seller.markupExternalListings) {
        this.formGroup.controls.markupExternalListings.setValue(this.seller.markupExternalListings);
      }
      if (this.seller.externalListingsMarkupBips) {
        this.formGroup.controls.externalListingsMarkupPercent.setValue(this.seller.externalListingsMarkupBips / 100);
      }
      if (this.seller.offersAllowed != null) {
        this.formGroup.controls.offersAllowed.setValue(this.seller.offersAllowed);
      }
      if (this.seller.autoDeclineOffersUnderBips) {
        this.formGroup.controls.autoDeclineOffersUnderPercent.setValue((this.seller.autoDeclineOffersUnderBips / 100).toString());
      }
    })
  }

  ngOnInit(): void { }

  cancelButtonPressed(): void {
    this.close();
  }

  saveButtonPressed(): void {
    this.isSaving = true

    var updateParams: any = { }
    if (this.formGroup.controls.type.dirty) {
      updateParams["type"] = this.formGroup.controls.type.value;
    }
    if (this.formGroup.controls.trusted.dirty) {
      updateParams["trusted"] = this.formGroup.controls.trusted.value;
    }

    if (this.formGroup.controls.overrideCommissionRatePercent.value) {
      updateParams["overrideCommissionRateBips"] = Number.parseFloat(this.formGroup.controls.overrideCommissionRatePercent.value) * 100;
    } else {
      updateParams["overrideCommissionRateBips"] = null
    }
    if (this.formGroup.controls.overrideSellerFeeDollars.value) {
      updateParams["overrideSellerFeeCents"] = Number.parseFloat(this.formGroup.controls.overrideSellerFeeDollars.value) * 100;
    } else {
      updateParams["overrideSellerFeeCents"] = null
    }
    if (this.formGroup.controls.externalListingSitemapUrl.value) {
      updateParams["externalListingSitemapUrl"] = this.formGroup.controls.externalListingSitemapUrl.value
    } else {
      updateParams["externalListingSitemapUrl"] = null
    }
    updateParams["markupExternalListings"] = this.formGroup.controls.markupExternalListings.value
    if (this.formGroup.controls.externalListingsMarkupPercent.value) {
      updateParams["externalListingsMarkupBips"] = this.formGroup.controls.externalListingsMarkupPercent.value * 100
    } else {
      updateParams["externalListingsMarkupBips"] = null
    }

    updateParams["offersAllowed"] = this.formGroup.controls.offersAllowed.value
    if (this.formGroup.controls.autoDeclineOffersUnderPercent.value) {
      updateParams["autoDeclineOffersUnderBips"] = Number.parseFloat(this.formGroup.controls.autoDeclineOffersUnderPercent.value) * 100;
    } else {
      updateParams["autoDeclineOffersUnderBips"] = null
    }

    this.sellersService.updateSellerProfile(this.data.sellerId, updateParams).subscribe({
      next: () => {
        this.close();
      },
      error: (error) => {
        console.log(error);
        this.isSaving = false;
      }
    });
  }

  deleteButtonPressed(): void {
    const dialogRef = this.dialog.open(ConfirmDeleteUserComponent, {
      data: {
        userId: this.seller.user.uuid
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.deletedUserId) {
        this.close();
        this.router.navigateByUrl('/marketplace/sellers');
      }
    });
  }

  close() {
    this.dialogRef.close({ updated : this.isSaving});    
  }

  isLoading(): boolean {
    return this.seller == null;
  }
}
