import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule}  from '@angular/cdk/drag-drop';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ImageCropperModule } from 'ngx-image-cropper';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { SidebarComponent } from './main/sidebar/sidebar.component';
import { ToolbarComponent } from './main/toolbar/toolbar.component';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { ImageViewComponent } from './common/image-view/image-view.component';
import { BrowseBrandsComponent } from './main/catalog/brands/browse-brands/browse-brands.component';
import { BrandsCreateComponent } from './main/catalog/brands/brands-create/brands-create.component';
import { BrowseModelsComponent } from './main/catalog/models/browse-models/browse-models.component';
import { MaterialsHomeComponent } from './main/catalog/materials/materials-home/materials-home.component';
import { MaterialsCreateComponent } from './main/catalog/materials/materials-create/materials-create.component';
import { BrowseSeriesComponent } from './main/catalog/series/browse-series/browse-series.component';
import { SeriesCreateComponent } from './main/catalog/series/series-create/series-create.component';
import { ModelsCreateComponent } from './main/catalog/models/models-create/models-create.component';
import { ColorsHomeComponent } from './main/catalog/colors/colors-home/colors-home.component';
import { ColorsCreateComponent } from './main/catalog/colors/colors-create/colors-create.component';
import { ComplicationsCreateComponent } from './main/catalog/complications/complications-create/complications-create.component';
import { ComplicationsHomeComponent } from './main/catalog/complications/complications-home/complications-home.component';
import { BrandsDetailComponent } from './main/catalog/brands/brands-detail/brands-detail.component';
import { SeriesDetailComponent } from './main/catalog/series/series-detail/series-detail.component';
import { ModelsDetailComponent } from './main/catalog/models/models-detail/models-detail.component';
import { EditSeriesComponent } from './main/catalog/series/edit-series/edit-series.component';
import { EditBrandComponent } from './main/catalog/brands/edit-brand/edit-brand.component';
import { EditModelComponent } from './main/catalog/models/edit-model/edit-model.component';
import { ListingsHomeComponent } from './main/marketplace/listings/listings-home/listings-home.component';
import { ListingsDetailComponent } from './main/marketplace/listings/listings-detail/listings-detail.component';
import { ImageGalleryDialog } from './common/image-gallery/image-gallery.component';
import { ListingsCreateComponent, CreateIssueDialog, EditListingPromotionDialog, CreateTagDialog } from './main/marketplace/listings/listings-create/listings-create.component';
import { OrdersHomeComponent } from './main/marketplace/orders/orders-home/orders-home.component';
import { OrdersDetailComponent } from './main/marketplace/orders/orders-detail/orders-detail.component';
import { FeaturesHomeComponent } from './main/content/features/features-home/features-home.component';
import { FeatureDetailComponent } from './main/content/features/feature-detail/feature-detail.component';
import { CreateFeatureComponent } from './main/content/features/create-feature/create-feature.component';
import { DiscountsHomeComponent } from './main/marketplace/discounts/discounts-home/discounts-home.component';
import { CreateDiscountComponent } from './main/marketplace/discounts/create-discount/create-discount.component';
import { DiscountDetailComponent } from './main/marketplace/discounts/discount-detail/discount-detail.component';
import { EditDiscountComponent } from './main/marketplace/discounts/edit-discount/edit-discount.component';
import { AuthModule, AuthHttpInterceptor } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';
import { SellerSelectComponent } from './common/seller-select/seller-select.component';
import { ModelChipComponent } from './common/model-chip/model-chip.component';
import { SellersHomeComponent } from './main/marketplace/sellers/sellers-home/sellers-home.component';
import { SellersDetailComponent } from './main/marketplace/sellers/sellers-detail/sellers-detail.component';
import { SellerChipComponent } from './common/seller-chip/seller-chip.component';
import { DeleteDialog } from './common/delete-dialog/delete-dialog.component';
import { EditSellerDetailsComponent } from './main/marketplace/sellers/edit-seller-details/edit-seller-details.component';
import { SearchHomeComponent } from './main/search/search-home/search-home.component';
import { SearchResultComponent } from './main/search/search-result/search-result.component';
import { BuyersHomeComponent } from './main/marketplace/buyers/buyers-home/buyers-home.component';
import { BuyersDetailComponent } from './main/marketplace/buyers/buyers-detail/buyers-detail.component';
import { IngestionRequestsHomeComponent } from './main/marketplace/ingestion-requests/ingestion-requests-home/ingestion-requests-home.component';
import { IngestionRequestDetailComponent } from './main/marketplace/ingestion-requests/ingestion-request-detail/ingestion-request-detail.component';
import { IngestionRequestCreateComponent } from './main/marketplace/ingestion-requests/ingestion-request-create/ingestion-request-create.component';
import { OffersHomeComponent } from './main/marketplace/offers/offers-home/offers-home.component';
import { OffersDetailComponent } from './main/marketplace/offers/offers-detail/offers-detail.component';
import { CounterOfferDialogComponent } from './main/marketplace/offers/counter-offer-dialog/counter-offer-dialog.component';
import { PriceHistoryComponent } from './main/marketplace/offers/price-history/price-history.component';
import { ImageUploadDialog } from './common/image-upload/image-upload.component';
import { TimelineComponent } from './common/timeline/timeline.component';
import { SearchOverlayComponent } from './main/search/search-overlay/search-overlay.component';
import { ErrorDialog } from './common/error-dialog/error-dialog.component';
import { BrowseListsComponent } from './main/content/lists/browse-lists/browse-lists.component';
import { BrowseCollectionsComponent } from './main/content/collections/browse-collections/browse-collections.component';
import { BrowseHomeFeedsComponent } from './main/content/home-feeds/browse-home-feeds/browse-home-feeds.component';
import { ModelCard } from './common/model-card/model-card.component';
import { ListingCard } from './common/listing-card/listing-card.component';
import { CollectionCard } from './common/collection-card/collection-card.component';
import { FeatureCard } from './common/feature-card/feature-card.component';
import { BrandCard } from './common/brand-card/brand-card.component';
import { HomeFeedDetailComponent } from './main/content/home-feeds/home-feed-detail/home-feed-detail.component';
import { CreateHomeFeedComponent } from './main/content/home-feeds/create-home-feed/create-home-feed.component';
import { AddSectionComponent } from './main/content/home-feeds/add-section/add-section.component';
import { ListSelectComponent } from './common/list-select/list-select.component';
import { UpdateSectionComponent } from './main/content/home-feeds/update-section/update-section.component';
import { ListDetailComponent } from './main/content/lists/list-detail/list-detail.component';
import { SortableListComponent } from './main/content/lists/list-detail/sortable-list.component';
import { CreateListComponent } from './main/content/lists/create-list/create-list.component';
import { DndModule } from '@ng-dnd/core';
import { DndSortableModule } from '@ng-dnd/sortable';
import { DndMultiBackendModule, MultiBackend, HTML5ToTouch } from '@ng-dnd/multi-backend';
import { CollectionSelectComponent } from './common/collection-select/collection-select.component';
import { ModelSelectComponent } from './common/model-select/model-select.component';
import { ListingSelectComponent } from './common/listing-select/listing-select.component';
import { BrandSelectComponent } from './common/brand-select/brand-select.component';
import { FeatureSelectComponent } from './common/feature-select/feature-select.component';
import { CollectionDetailComponent } from './main/content/collections/collection-detail/collection-detail.component';
import { CreateCollectionComponent } from './main/content/collections/create-collection/create-collection.component';
import { BrowseArticlesComponent } from './main/content/articles/browse-articles/browse-articles.component';
import { ArticleDetailComponent } from './main/content/articles/article-detail/article-detail.component';
import { CreateArticleComponent } from './main/content/articles/create-article/create-article.component';
import { ArticleSelectComponent } from './common/article-select/article-select.component';
import { ArticleCardComponent } from './common/article-card/article-card.component';
import { BuyerSelectComponent } from './common/buyer-select/buyer-select.component';
import { BrowseBillingAccountsComponent } from './main/payments/browse-billing-accounts/browse-billing-accounts.component';
import { BillingAccountDetailComponent } from './main/payments/billing-account-detail/billing-account-detail.component';
import { AdjustCreditBalanceDialogComponent } from './main/payments/adjust-credit-balance-dialog/adjust-credit-balance-dialog.component';
import { BuyerChipComponent } from './common/buyer-chip/buyer-chip.component';
import { ListingChipComponent } from './common/listing-chip/listing-chip.component';
import { EditBuyerDetailsComponent } from './main/marketplace/buyers/edit-buyer-details/edit-buyer-details.component';
import { BrowseHighlightsComponent } from './main/content/highlights/browse-highlights/browse-highlights.component';
import { CreateHighlightComponent } from './main/content/highlights/create-highlight/create-highlight.component';
import { HighlightDetailComponent } from './main/content/highlights/highlight-detail/highlight-detail.component';
import { ProgramSelectComponent } from './common/program-select/program-select.component';
import { ReturnsDetailComponent } from './main/marketplace/returns/returns-detail/returns-detail.component';
import { ReturnsHomeComponent } from './main/marketplace/returns/returns-home/returns-home.component';
import { OrderChipComponent } from './common/order-chip/order-chip.component';
import { CreateReturnComponent } from './main/marketplace/returns/create-return/create-return.component';
import { TagSelectComponent } from './common/tag-select/tag-select.component';
import { ClientHeaderInterceptor } from './_http-interceptors/client-header-interceptor';
import { DropsHomeComponent } from './main/marketplace/drops/drops-home/drops-home.component';
import { DropsDetailComponent } from './main/marketplace/drops/drops-detail/drops-detail.component';
import { SortableDropComponent } from './main/marketplace/drops/drops-detail/sortable-drop.component';
import { BrowseMaxBidsComponent } from './main/marketplace/max-bids/browse-max-bids/browse-max-bids.component';
import { MaxBidDetailComponent } from './main/marketplace/max-bids/max-bid-detail/max-bid-detail.component';
import { BrowseAuctionsComponent } from './main/marketplace/auctions/browse-auctions/browse-auctions.component';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { BidsTableComponent } from './main/marketplace/listings/listings-detail/bids-table/bids-table.component';
import { BrowseVerificationReportsComponent } from './main/verification/verification-reports/browse-verification-reports/browse-verification-reports.component';
import { BrowseDiagnosticReportsComponent } from './main/verification/diagnostic-reports/browse-diagnostic-reports/browse-diagnostic-reports.component';
import { BrowseAuthenticationReportsComponent } from './main/verification/authentication-reports/browse-authentication-reports/browse-authentication-reports.component';
import { BrowseIntakeReportsComponent } from './main/verification/intake-reports/browse-intake-reports/browse-intake-reports.component';
import { IntakeReportDetailComponent } from './main/verification/intake-reports/intake-report-detail/intake-report-detail.component';
import { DiagnosticReportDetailComponent } from './main/verification/diagnostic-reports/diagnostic-report-detail/diagnostic-report-detail.component';
import { AuthenticationReportDetailComponent } from './main/verification/authentication-reports/authentication-report-detail/authentication-report-detail.component';
import { VerificationReportDetailComponent } from './main/verification/verification-reports/verification-report-detail/verification-report-detail.component';
import { ChangeOrderAddressDialogComponent } from './main/marketplace/orders/orders-detail/change-order-address-dialog/change-order-address-dialog.component';
import { EditMaxBidComponent } from './main/marketplace/max-bids/edit-max-bid/edit-max-bid.component';
import { ModelsImageCheckComponent } from './main/catalog/models/models-image-check/models-image-check.component';
import { BrowseCategoriesComponent } from './main/catalog/categories/browse-categories/browse-categories.component';
import { CreateCategoryComponent } from './main/catalog/categories/create-category/create-category.component';
import { ResourceChangeHistoryButtonComponent } from './common/change-history-dialog/history-button.component';
import { ResourceChangeHistoryDialog } from './common/change-history-dialog/history-dialog.component';
import { PurchaseRequestsHomeComponent } from './main/marketplace/purchase-requests/purchase-requests-home/purchase-requests-home.component';
import { PurchaseRequestsDetailComponent } from './main/marketplace/purchase-requests/purchase-requests-detail/purchase-requests-detail.component';
import { PurchaseRequestsCreateComponent } from './main/marketplace/purchase-requests/purchase-requests-create/purchase-requests-create.component';
import { ConfirmDeleteUserComponent } from './common/confirm-delete-user/confirm-delete-user.component';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    SidebarComponent,
    ToolbarComponent,
    FeaturesHomeComponent,
    FeatureDetailComponent,
    CreateFeatureComponent,
    DiscountsHomeComponent,
    CreateDiscountComponent,
    DiscountDetailComponent,
    EditDiscountComponent,
    UnauthorizedComponent,
    ImageViewComponent,
    SellerSelectComponent,
    BuyerSelectComponent,
    ModelChipComponent,
    SellerChipComponent,
    BrowseBrandsComponent,
    BrandsCreateComponent,
    BrowseModelsComponent,
    ModelsImageCheckComponent,
    MaterialsHomeComponent,
    MaterialsCreateComponent,
    BrowseSeriesComponent,
    SeriesCreateComponent,
    ModelsCreateComponent,
    ColorsHomeComponent,
    ColorsCreateComponent,
    ComplicationsCreateComponent,
    ComplicationsHomeComponent,
    BrandsDetailComponent,
    SeriesDetailComponent,
    ModelsDetailComponent,
    EditSeriesComponent,
    EditBrandComponent,
    EditModelComponent,
    ListingsHomeComponent,
    ListingsDetailComponent,
    ImageGalleryDialog,
    ImageUploadDialog,
    ListingsCreateComponent,
    CreateIssueDialog,
    CreateTagDialog,
    EditListingPromotionDialog,
    OrdersHomeComponent,
    OrdersDetailComponent,
    SellersHomeComponent,
    SellersDetailComponent,
    DeleteDialog,
    EditSellerDetailsComponent,
    SearchHomeComponent,
    SearchResultComponent,
    BuyersHomeComponent,
    BuyersDetailComponent,
    IngestionRequestsHomeComponent,
    IngestionRequestDetailComponent,
    IngestionRequestCreateComponent,
    OffersHomeComponent,
    OffersDetailComponent,
    CounterOfferDialogComponent,
    PriceHistoryComponent,
    TimelineComponent,
    SearchOverlayComponent,
    ErrorDialog,
    BrowseListsComponent,
    BrowseCollectionsComponent,
    BrowseHomeFeedsComponent,
    HomeFeedDetailComponent,
    ModelCard,
    ListingCard,
    CollectionCard,
    FeatureCard,
    BrandCard,
    CreateHomeFeedComponent,
    AddSectionComponent,
    ModelSelectComponent,
    ListingSelectComponent,
    BrandSelectComponent,
    ListSelectComponent,
    CollectionSelectComponent,
    FeatureSelectComponent,
    ArticleSelectComponent,
    UpdateSectionComponent,
    ListDetailComponent,
    SortableListComponent,
    CreateListComponent,
    CollectionDetailComponent,
    CreateCollectionComponent,
    BrowseArticlesComponent,
    ArticleDetailComponent,
    CreateArticleComponent,
    ArticleCardComponent,
    BrowseBillingAccountsComponent,
    BillingAccountDetailComponent,
    AdjustCreditBalanceDialogComponent,
    BuyerChipComponent,
    ListingChipComponent,
    OrderChipComponent,
    EditBuyerDetailsComponent,
    BrowseHighlightsComponent,
    CreateHighlightComponent,
    HighlightDetailComponent,
    ProgramSelectComponent,
    ReturnsDetailComponent,
    ReturnsHomeComponent,
    CreateReturnComponent,
    TagSelectComponent,
    DropsHomeComponent,
    DropsDetailComponent,
    SortableDropComponent,
    BrowseMaxBidsComponent,
    MaxBidDetailComponent,
    BrowseAuctionsComponent,
    BidsTableComponent,
    BrowseVerificationReportsComponent,
    BrowseDiagnosticReportsComponent,
    BrowseAuthenticationReportsComponent,
    BrowseIntakeReportsComponent,
    IntakeReportDetailComponent,
    DiagnosticReportDetailComponent,
    AuthenticationReportDetailComponent,
    VerificationReportDetailComponent,
    ChangeOrderAddressDialogComponent,
    EditMaxBidComponent,
    BrowseCategoriesComponent,
    CreateCategoryComponent,
    ResourceChangeHistoryButtonComponent,
    ResourceChangeHistoryDialog,
    PurchaseRequestsHomeComponent,
    PurchaseRequestsDetailComponent,
    PurchaseRequestsCreateComponent,
    ConfirmDeleteUserComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatTableModule,
    MatSortModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatCardModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatDividerModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatStepperModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMenuModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatDialogModule,
    MatRadioModule,
    BrowserAnimationsModule,
    BrowserAnimationsModule,
    DragDropModule,
    MatTooltipModule,
    DndSortableModule,
    DndMultiBackendModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule,
    NgxMatDatetimePickerModule,
    DndModule.forRoot({ backend: MultiBackend, options: HTML5ToTouch }),
    AuthModule.forRoot({
      domain: environment.authDomain,
      clientId: environment.authClientId,
      authorizationParams: {
        audience: 'platform-core',
        redirect_uri: window.location.origin
      },
      httpInterceptor: {
        allowedList: [
          {
            uri: 'https://api.sandbox.bezel.cloud/*',
            tokenOptions: {
              authorizationParams: {
                audience: 'platform-core',
              }
            }
          },
          {
            uri: 'https://api.bezel.cloud/*',
            tokenOptions: {
              authorizationParams: {
                audience: 'platform-core',
              }
            }
          },
          {
            uri: 'http://127.0.0.1:8080/*',
            tokenOptions: {
              authorizationParams: {
                audience: 'platform-core',
              }
            }
          }
        ]
      }
    }),
    ImageCropperModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ClientHeaderInterceptor,
      multi: true
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        subscriptSizing: 'dynamic'
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
