import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import Listing from '../models/listing.model';
import IngestionRequest from '../models/ingestion-request.model';
import SellerProfile from '../models/seller-profile.model';
import User from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class IngestionRequestsService {
  constructor(private http: HttpClient) { }

  public getIngestionRequests(start: number, pageSize: number, status: string | null, seller: number | null, listing: number | null, flagged: boolean | null, assignee: string | null, sortKey: string | null): Observable<any> {
    var path = `/marketplace/ingestion-requests?start=${start}&limit=${pageSize}&expand[]=sellerProfile.user&expand[]=assignee`;
    if (!!seller) {
      path += `&sellerProfile[]=${seller}`
    }
    if (!!listing) {
      path += `&listing[]=${listing}`
    }
    if (!!status) {
      path += `&status[]=${status}`;
    }
    if (!!flagged) {
      path += `&flagged=${flagged}`;
    }
    if (!!assignee) {
      path += `&assignee[]=${assignee}`;
    }
    if (!!sortKey) {
      path += `&sort=${sortKey}`
    }
    return this.http.get<IngestionRequest[]>(environment.apiUrl + path, {observe: 'response'}).pipe(
      map(response => {
        return {
          data: response.body!.map(i => this.toIngestionRequest(i)), 
          totalCount: response.headers.get('X-Total-Count')}
      })
    );
  }

  public getIngestionRequest(ingestionRequestId: number): Observable<IngestionRequest> {
    return this.http.get<Listing>(environment.apiUrl + `/marketplace/ingestion-requests/${ingestionRequestId}?expand[]=sellerProfile.user&expand[]=assignee`).pipe(
      map(ingestionRequest => this.toIngestionRequest(ingestionRequest))
    );
  }

  public archiveIngestionRequest(ingestionRequestId: number) {
    return this.http.post(environment.apiUrl + `/marketplace/ingestion-requests/${ingestionRequestId}/archive`, { });
  }

  public createIngestionRequest(params: any): Observable<IngestionRequest> {
    return this.http.post<IngestionRequest>(environment.apiUrl + `/marketplace/ingestion-requests`, params);
  }

  public updateIngestionRequest(ingestionRequestId: number, params: any): Observable<IngestionRequest> {
    return this.http.patch<IngestionRequest>(environment.apiUrl + `/marketplace/ingestion-requests/${ingestionRequestId}`, params);
  }

  private toIngestionRequest(ingestionRequest: any): IngestionRequest {
    var ingestionRequest = Object.assign(new IngestionRequest(), ingestionRequest)
    ingestionRequest.sellerProfile = Object.assign(new SellerProfile(), ingestionRequest.sellerProfile)
    if (ingestionRequest.listing) {
        ingestionRequest.listing = Object.assign(new Listing(), ingestionRequest.listing)
    }
    if (ingestionRequest.assignee) {
      ingestionRequest.assignee = Object.assign(new User(), ingestionRequest.assignee)
    }
    return ingestionRequest
  }
}
